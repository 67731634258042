class HoursDescriptionEditor {
	private value: string = '';
	private params: any;
	private eGui: HTMLElement;
	private data: string[] = [];

	constructor() {
		this.eGui = document.createElement('div');
		this.eGui.classList.add('w-100', 'ag-input-text-wrapper');
	}

	public getGui() {
		return this.eGui;
	}

	public getValue() {
		return this.value;
	}

	public afterGuiAttached() {
		const input = this.eGui.querySelector('input');

		if (input) {
			input.focus();

			if (this.params.charPress) {
				input.value = this.params.charPress;

				this.value = input.value;

				this.updateValue();
			}
		}
	}

	public isPopup() {
		return false;
	}

	public init(params: any) {
		this.params = params;

		this.data = params.list();

		this.value = params.value;
		this.eGui.innerHTML = '<input type="text" class="w-100 form-control-sm p-0" style="height: 26px;">';

		const input = this.eGui.querySelector('input') as HTMLInputElement;

		input.value = params.value || '';

		input.addEventListener2('input', (e: any) => {
			const value = input.value;

			if (e.inputType !== 'deleteContentBackward') {
				this.updateValue();
			}

			this.value = value;
		});

		input.addEventListener2('keydown', (e: KeyboardEvent) => {
			if ([39, 9, 13].includes(e.keyCode)) {
				this.value = input.value;
			}
		});

		input.addEventListener2('blur', () => {
			params.api.stopEditing();
		});
	}

	private updateValue() {
		const input = this.eGui.querySelector('input');

		if (input) {
			const value = input.value;

			for (const item of this.data) {
				if (item.toLowerCase().startsWith(value.toLowerCase())) {
					input.value += item.substring(value.length);

					input.setSelectionRange(value.length, item.length);

					break;
				}
			}
		}
	}

	public destroy() {

	}
}

export default HoursDescriptionEditor;
