import { Module, Modules, Router } from '@autoprog/core-client';

import C_President from './js/controllers/President';
import T_president from './tpl/president.html';

import './css/president.scss';

import Navigation from '@libs/Navigation';

class PresidentModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);
		/*Permissions.register('PRESIDENT', 'Président');
		Permissions.register('PRESIDENT.SHOW', 'Vopir la page');*/
	}

	public async preInit() {
		const navigation = Navigation.getInstance();
		navigation.addCategory({
			name: 'Outils',
			order: 200
		});
		navigation.addLink({
			order: 100,
			category: 'Outils',
			name: 'Président',
			type: 'module',
			module: 'president',
			permissions: ['PRESIDENT'],
			icon: 'vip-crown/fill'
		});
	}

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: '/module/president',
			controller: C_President,
			tpl: T_president,
			requireValidUser: true
		});
	}

	public async postInit() {

	}
}

Modules.registerModule('President', PresidentModule);
