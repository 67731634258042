import { Controller, Tabs } from '@autoprog/core-client';

import C_Ascii from '../tabs/Ascii';
import C_Crypto from '../tabs/Crypto';
import C_JSON_CSV from '../tabs/JSON_CSV';
import C_KeyCode from '../tabs/KeyCode';

import T_Ascii from '../../tpl/tabs/ascii.html';
import T_Crypto from '../../tpl/tabs/crypto.html';
import T_JSON_CSV from '../../tpl/tabs/JSON_CSV.html';
import T_KeyCode from '../../tpl/tabs/keyCode.html';

class Tools extends Controller {
	constructor(el: HTMLElement) {
		super(el);

		const tabs = new Tabs({
			navElement: el.querySelector('.tabs-container') as HTMLElement,
			renderElement: el.querySelector('#page-content') as HTMLElement
		});

		tabs.add({
			name: 'Table ASCII',
			controller: C_Ascii,
			template: T_Ascii,
			default: true
		});

		tabs.add({
			name: 'Key Code',
			controller: C_KeyCode,
			template: T_KeyCode
		});

		tabs.add({
			name: 'JSON/CSV',
			controller: C_JSON_CSV,
			template: T_JSON_CSV
		});

		tabs.add({
			name: 'Crypto',
			controller: C_Crypto,
			template: T_Crypto
		});
	}

	public destructor() {

	}
}

export default Tools;
