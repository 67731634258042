import { Module, Modules, Router } from '@autoprog/core-client';

import C_Module from './js/controllers/QrCode';

import T_tabs from '@tpl/tabs.html';

import Navigation from '@libs/Navigation';

class QRCodeModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);
		/*Permissions.register('QRCODE/CODES', 'Qcrode Codes');
		Permissions.register('QRCODE/USERS', 'Qcrode Utilisateur');

		Permissions.register('QRCODE.SHOW', 'Voir');

		const keys = ['QRCODE/CODES', 'QRCODE/USERS'];

		for (const item of keys) {
			Permissions.register(`${item.toUpperCase()}.SHOW`, 'Voir');
			Permissions.register(`${item.toUpperCase()}.ADD`, 'Créer');
			Permissions.register(`${item.toUpperCase()}.EDIT`, 'Modifier');
			Permissions.register(`${item.toUpperCase()}.DELETE`, 'Supprimer');
			Permissions.register(`${item.toUpperCase()}.DUPLICATE`, 'Dupliquer');
		}*/
	}

	public async preInit() {
		const navigation = Navigation.getInstance();
		navigation.addCategory({
			name: 'Outils',
			order: 200
		});
		navigation.addLink({
			order: 4,
			category: 'Outils',
			name: 'QrCode',
			type: 'module',
			module: 'qrcode',
			permissions: ['QRCODES', 'QRCODES._USERS'],
			icon: 'qr-code/line'
		});
	}

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: '/module/qrcode',
			controller: C_Module as any,
			tpl: T_tabs,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/qrcode/:tabs',
			controller: C_Module as any,
			tpl: T_tabs,
			requireValidUser: true
		});
	}

	public async postInit() {

	}
}

Modules.registerModule('QRCode', QRCodeModule);
