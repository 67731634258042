import { GetContextMenuItemsParams, MenuItemDef } from '@ag-grid-community/core';
import { toaster } from '@autoprog/core-client';

import C_database from '@js/controllers/Database';

import S_Qr_Code from '@services/QrCode/QrCodeCodeService';
import moment from 'moment';

import M_codes from '../modals/Codes';

class CodesTabs extends C_database {
	constructor(el: HTMLElement) {
		super(el, {
			name: 'Codes',
			database: 'qrcode_codes'
		});

		const N_add = document.querySelector('#add') as HTMLElement;

		const canceller = N_add.addEventListener2('click', () => {
			new M_codes().open().then(() => this.refreshData());
		});

		this.cancellers.push(canceller);
	}

	protected buttonEdit(params: any) {
		const addEddit = new M_codes(params.value);
		addEddit.open().then(() => this.refreshData());
	}

	protected addOnButtonGrid(params: any): HTMLElement[] {
		const N_download = document.createElement('button');
		N_download.classList.add('h-100', 'py-0', 'btn-transparent');
		N_download.innerHTML = '<i class="h5 icon icon-solid-file-download"></i>';
		N_download.addEventListener2('click', () => this.downloadQrCode(params.data));

		return [N_download];
	}

	private async downloadQrCode(data: { [key: string]: any }) {
		const imageSrc = await S_Qr_Code.getInstance().getRouteGenerateQrCodeByData(data.urlRedirection as string, data.notes as string, data.codeEncloser as string);
		const image = await fetch(imageSrc);
		const imageBlog = await image.blob();
		const imageURL = URL.createObjectURL(imageBlog);

		const link = document.createElement('a');
		link.href = imageURL;
		link.download = `${data.name}-${data.codeEncloser}-${moment().format('YYYY-MM-DD')}.png`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	protected async buttonDuplicate(params: any) {
		try {
			const data = await this.tableService.duplicate(params.value);

			toaster.success('Duplication terminée.');

			new M_codes(data.data._id).open().then(() => {
				this.refreshData();
			}).catch(() => {
				this.refreshData();
			});
		} catch (error) {
			console.error(error);
			toaster.error('Impossible de dupliquer la donnée.', 'Erreur');
		}
	}

	protected getContextMenu(params: GetContextMenuItemsParams): MenuItemDef[] {
		return [
			{
				name: 'Télécharger',
				action: () => this.downloadQrCode(params.node.data),
				icon: '<i class="icon icon-solid-file-download"></i>'
			}
		];
	}
}

export default CodesTabs;
