import C_database from '@js/controllers/Database';
import M_users from '../modals/Users';

import S_Qr_User from '@services/QrCode/QrCodeUserService';

import { toaster } from '@autoprog/core-client';

class UsersTabs extends C_database {
	constructor(el: HTMLElement) {
		super(el, {
			name: 'Utilisateur',
			database: 'qrcode_users'
		});

		const N_add = document.querySelector('#add') as HTMLElement;

		N_add.classList.remove('d-none');

		const canceller = N_add.addEventListener2('click', () => {
			new M_users().open().finally(() => this.refreshData());
		});

		this.cancellers.push(canceller);
	}

	protected async buttonDelete(params: any) {
		await S_Qr_User.getInstance().delete(params.value);
		this.refreshData();
		toaster.success('Utilisateur supprime');
	}

	protected buttonEdit(params: any) {
		const addEddit = new M_users(params.value);
		addEddit.open().finally(() => this.refreshData());
	}
}

export default UsersTabs;
