import { Tab } from '@autoprog/core-client';

import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

class AsciiTab extends Tab {
	private el: HTMLElement;

	constructor(el: HTMLElement) {
		super(el);

		this.el = el;

		const data: { [key: string]: any }[] = [];

		const caract: { [key: number]: string } = {
			0: 'NUL',
			1: 'SOH',
			2: 'STX',
			3: 'ETX',
			4: 'EOT',
			5: 'ENQ',
			6: 'ACK',
			7: 'BEL',
			8: 'BS',
			9: 'HT',
			10: 'LF',
			11: 'VT',
			12: 'FF',
			13: 'CR',
			14: 'SO',
			15: 'SI',
			16: 'DLE',
			17: 'DC1',
			18: 'DC2',
			19: 'DC3',
			20: 'DC4',
			21: 'NAK',
			22: 'SYN',
			23: 'ETB',
			24: 'CAN',
			25: 'EM',
			26: 'SUB',
			27: 'ESC',
			28: 'FS',
			29: 'GS',
			30: 'RS',
			31: 'US',
			32: 'SP',
			127: 'DEL'
		};

		const description: { [key: number]: string } = {
			0: 'Null char.',
			1: 'Start of Header',
			2: 'Start of Text',
			3: 'End of Text',
			4: 'End of Transmission',
			5: 'Enquiry',
			6: 'Acknowledgment',
			7: 'Bell',
			8: 'Backspace',
			9: 'Horizontal Tab',
			10: 'Line Feed',
			11: 'Vertical Tab',
			12: 'Form Feed',
			13: 'Carriage Return',
			14: 'Shift Out',
			15: 'Shift In',
			16: 'Data Link Escape',
			17: 'XON)(Device Control 1',
			18: 'Device Control 2',
			19: 'XOFF)(Device Control 3',
			20: 'Device Control 4',
			21: 'Negative Acknowledgement',
			22: 'Synchronous Idle',
			23: 'End of Trans. Block',
			24: 'Cancel',
			25: 'End of Medium',
			26: 'Substitute',
			27: 'Escape',
			28: 'File Separator',
			29: 'Group Separator',
			30: 'Request to Send)(Record Separator',
			31: 'Unit Separator',
			32: 'Space',
			33: 'exclamation mark',
			34: 'double quote',
			35: 'number sign',
			36: 'dollar sign',
			37: 'percent',
			38: 'ampersand',
			39: 'single quote',
			40: 'left opening parenthesis',
			41: 'right closing parenthesis',
			42: 'asterisk',
			43: 'plus',
			44: 'comma',
			45: 'minus or dash',
			46: 'dot',
			47: 'forward slash',
			58: 'colon',
			59: 'semi-colon',
			60: 'less than sign',
			61: 'equal sign',
			62: 'greater than sign',
			63: 'question mark',
			64: 'AT symbol',
			91: 'left opening bracket',
			92: 'back slash',
			93: 'right closing bracket',
			94: 'caret cirumflex',
			95: 'underscore',
			123: 'left opening brace',
			124: 'vertical bar',
			125: 'right closing brace',
			126: 'tilde',
			127: 'delete'
		};

		for (let i = 0; i < 128; i++) {
			const obj = {
				decimal: ('000' + i).slice(-3),
				octal: ('000' + i.toString(8)).slice(-3),
				hex: ('00' + i.toString(16)).slice(-2).toUpperCase(),
				binaire: ('00000000' + i.toString(2)).slice(-8),
				caract: caract[i] || String.fromCharCode(i),
				description: description[i] || ''
			};

			data.push(obj);
		}

		const gridOptions: GridOptions = {
			rowData: data,
			columnDefs: [{
				headerName: 'Decimal',
				field: 'decimal'
			}, {
				headerName: 'Octal',
				field: 'octal'
			}, {
				headerName: 'Hex',
				field: 'hex'
			}, {
				headerName: 'Binaire',
				field: 'binaire'
			}, {
				headerName: 'Caractère',
				field: 'caract'
			}, {
				headerName: 'Description',
				field: 'description'
			}],
			defaultColDef: {
				filter: 'agTextColumnFilter',
				resizable: true,
				sortable: true,
				suppressMenu: true,
				floatingFilterComponentParams: {
					suppressFilterButton: true
				}
			},
			suppressDragLeaveHidesColumns: true,
			floatingFilter: true
		};

		const N_grid = this.el.querySelector('#grid') as HTMLElement;

		new Grid(N_grid, gridOptions, { modules: AllModules });
	}
}

export default AsciiTab;
