import { Modal, toaster } from '@autoprog/core-client';

import h from 'hyperscript';

import T_selectPlayers from '../../tpl/modals/selectPlayers.html';

import Game from '../libs/Game';
import PlayersService from '../services/PlayersService';

class SelectPlayers extends Modal {
	private selectedPlayers: Set<string>;
	private game: Game;

	public constructor(game: Game) {
		super({
			tpl: T_selectPlayers,
			backdrop: 'static'
		});

		this.selectedPlayers = new Set();
		this.game = game;

		this.on('opened', async () => {
			const ps = new PlayersService();

			const N_playersContainer = this.element.querySelector('#players-container') as HTMLElement;
			const players = await ps.getAllSorted();

			this.selectedPlayers = new Set(this.game.getPlayers().keys());

			for (const player of players) {
				if (player.firstname && player.lastname) {
					const N_btn = h('div.picture', `${player.firstname[0]}${player.lastname[0]}`);

					if (this.selectedPlayers.has(player._id)) {
						N_btn.classList.add('checked');
					}

					N_btn.addEventListener2('click', () => {
						const checked = N_btn.classList.toggle('checked');

						if (checked) {
							this.selectedPlayers.add(player._id);
						} else {
							this.selectedPlayers.delete(player._id);
						}
					});

					N_playersContainer.appendChild(
						h('div.player', { attrs: { 'data-id': player._id } },
							N_btn,
							h('span.name', `${player.lastname[0]}. ${player.firstname}`)
						)
					);
				}
			}
		});
	}

	public async resolve() {
		const selectedPlayers = Array.from(this.selectedPlayers.values());

		if (selectedPlayers.length < 3) {
			toaster.error('Sélectionnez 3 joueurs minimum');
		} else if (selectedPlayers.length > 9) {
			toaster.error('Sélectionnez 9 joueurs maximum');
		} else {
			await this.game.setPlayers(selectedPlayers);
			super.resolve();
		}
	}
}

export default SelectPlayers;
