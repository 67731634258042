import { LoggedUser, Module, Modules, Router, SocketIO, global } from '@autoprog/core-client';

import semver from 'semver';

import C_Home from './js/controllers/Home';
import M_modal from './js/modals/Changelog';
import T_Home from './tpl/home.html';

import S_ChangelogService from '@services/ChangelogService';
import S_UserAddon from '@services/User/UserAddonService';
import Utils from '@libs/utils/Utils';

import UtilsVersion from './js/utils/ConvertVersion';

import './css/changelog.scss';

class ChangelogModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);
	}

	public async preInit() {

	}

	public async init() {
		global.CHANGELOG = await S_ChangelogService.getInstance().getAll();

		//Récupération du numéro de la dernière version de l'application
		const versions = global.CHANGELOG.match(/([0-9]{1,}.[0-9]{1,}.[0-9]{1,}(-[0-9]{0,1})?)/gmi);
		global.VERSION = versions[0];

		const router = Router.getInstance();

		router.addRoute({
			url: '/module/changelog/home',
			controller: C_Home as any,
			tpl: T_Home,
			requireValidUser: true
		});
	}

	public async postInit() {
		const io = SocketIO.getInstance();

		io.on('changelog.version', (version: string) => {
			const N_button = document.querySelector('#button-update') as HTMLElement;

			if (version !== global.VERSION) {
				N_button?.classList.remove('d-none');
			}
		});

		if (!global.IS_MOBILE) {
			const N_navbar = document.querySelector('#navbar-right-content') as HTMLElement;

			const N_changelog = document.createElement('button');
			N_changelog.classList.add('circle-btn');

			N_changelog.innerHTML = `
                <i class="icon icon icon-solid-code"></i>
            `;

			N_changelog.title = 'Changelog';

			N_changelog.addEventListener('click', () => {
				window.open(window.location.origin + '/#module/changelog/home');
			});

			const N_changeLogAlert = document.createElement('div');
			N_changeLogAlert.style.whiteSpace = 'nowrap';
			N_changeLogAlert.classList.add('mx-2', 'd-none', 'animated', 'flash', 'infinite', 'text-danger', 'mt-1');
			N_changeLogAlert.setAttribute('confirmation', '');

			N_changeLogAlert.id = 'button-update';

			N_changeLogAlert.title = 'Mise à jour disponible';

			N_changeLogAlert.innerHTML = '<i class="h2 icon icon-warning"></i>';

			N_changeLogAlert.addEventListener('click', () => {
				location.reload();
			});

			N_navbar.appendChild(N_changeLogAlert);
			N_navbar.appendChild(N_changelog);

			const loggedUser = LoggedUser.getInstance();

			if (Utils.userID) {
				this.openChangeLog(Utils.userID);
			}

			loggedUser.on('login', () => {
				this.openChangeLog(Utils.userID);
			});
		}
	}

	private async openChangeLog(idUser: string) {
		if (!global.IS_ELECTRON) {
			let oldData: { [key: string]: string } = {
				_id: idUser
			};
			try {
				const data = await S_UserAddon.getInstance().getById(idUser);

				data.lastVersion = UtilsVersion.convert(data.lastVersion);
				const oldVersion = UtilsVersion.convert(global.VERSION);

				if (semver.lt(data.lastVersion, oldVersion)) {
					new M_modal(data.lastVersion).open();

					oldData = {
						...oldData,
						...data
					};
				}
			} catch (e) {
				new M_modal().open().then(() => {

				});
			}

			//On actualise la dernière version connue dans tous les cas
			oldData.lastVersion = global.VERSION;

			await S_UserAddon.getInstance().save(oldData);
		}
	}
}

Modules.registerModule('Changelog', ChangelogModule);
