import { Form } from '@autoprog/core-client';

import Modal from '@libs/Modal';

import T_modal from '../../tpl/modals/travel.html';

import S_Travels from '@services/Hours/TravelsService';

import FilesTab from '@libs/customElement/FilesTab';

import CE_Select2 from '@libs/customElement/Select2';

class Travel extends Modal {
	private form: Form | null = null;

	private _options: { [key: string]: any } = {};

	private N_FilesTab: FilesTab | null = null;

	constructor(options: { [key: string]: any } = {}) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this._options = options;

		this.on('opened', async () => {
			this.initFiles();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			const N_user = this.element.querySelector('[name="infos.user"]') as CE_Select2;

			N_user.create(this.element);

			this.form = new Form(this.element.querySelector('form') as HTMLFormElement);

			const { data } = await S_Travels.getInstance().getDataToModal(options.id, options);

			this.form.setData(data.data);

			this.N_FilesTab!.data = data.data.attachments;

			N_user.postInit();

			this.N_FilesTab!.postInit();

			N_save.addEventListener2('click', async () => {
				N_save.disabled = true;

				N_save.loading(new Promise(async () => {
					await this.save();

					this.resolve();
				}));
			});
		});
	}

	private initFiles() {
		this.N_FilesTab = this.element.querySelector(FilesTab.tagName) as FilesTab;

		this.N_FilesTab.setParentElement(this.element);

		this.N_FilesTab.setCallback(() => {
			this.save();
		});
	}

	private async save() {
		if (this.form) {
			const data = this.form.getData() as any;

			data._id = this._options.id;

			if (data.infos.startDate) {
				data.infos.startDate = data.infos.startDate.format('x');
			}

			if (data.infos.endDate) {
				data.infos.endDate = data.infos.endDate.format('x');
			}

			data.attachments = this.N_FilesTab!.data;

			await S_Travels.getInstance().save(data);
		}
	}
}

export default Travel;
