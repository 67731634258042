import { Form, Modal } from '@autoprog/core-client';

import '../../css/user.css';
import T_modal from '../../tpl/modals/users.html';

import S_Qr_User from '@services/QrCode/QrCodeUserService';

import _ from 'lodash';

import moment, { Moment } from 'moment';

class UsersModal extends Modal {
	private sitesSelected: Map<string, any> = new Map();

	private form: Form | null = null;

	private id: string = '';

	constructor(id: string = '') {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.id = id;

		this.on('opened', async () => {
			const N_form = this.element.querySelector('.modal-body') as HTMLFormElement;

			this.form = new Form(N_form);

			this.getData();
		});
	}

	private async getData() {
		const { data } = await S_Qr_User.getInstance().getDataToModal(this.id);

		this.form!.setData(data.data);

		for (const site of data.data.sites) {
			this.sitesSelected.set(site.id, {
				id: site.id,
				text: site.text,
				customer: site.customer
			});
		}

		this.initSites(data.customers, data.sites);
		this.initRadioBoxPrivileges();

		if (data.data.privilege === 'all') {
			this.privilegeAll();
		} else {
			this.privilegeSites();
		}

		const N_sites = this.element.querySelector('#container-sites') as HTMLElement;

		this.initSearch(N_sites, 'search-customers', 'customers');
		this.initSearch(N_sites, 'search-sites', 'sites');
		this.initSearch(N_sites, 'search-select-sites', 'select-sites');

		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		N_save.addEventListener2('click', () => {
			this.save();
		});
	}

	private async initSites(customers: any[], sites: any[]) {
		const N_container = this.element.querySelector('#container-sites') as HTMLElement;

		const N_Customer = N_container.querySelector('#customers') as HTMLSelectElement;
		const N_Sites = N_container.querySelector('#sites') as HTMLSelectElement;
		const N_select = N_container.querySelector('#select-sites') as HTMLSelectElement;

		const customersDisplay: { [key: string]: any } = {};

		for (const customer of customers) {
			N_Customer.appendChild(new Option(customer.text, customer.id));
			customersDisplay[customer.id] = customer.text;
		}

		const groupByCustomer = _.groupBy(sites, 'customer');

		N_Customer.addEventListener2('change', () => {
			const options = N_Customer.querySelectorAll('option') as NodeListOf<HTMLOptionElement>;

			N_Sites.innerHTML = '';

			for (const option of options) {
				if (option.selected) {
					const N_optGroup = document.createElement('optgroup');
					N_optGroup.label = customersDisplay[option.value];
					N_Sites.appendChild(N_optGroup);

					const N_option = new Option('Tous les sites', `all_site_${option.value}`);
					N_option.dataset.customer = option.value;
					N_Sites.appendChild(N_option);

					for (const site of (groupByCustomer[option.value] || [])) {
						const N_option = new Option(site.text, site.id);
						N_option.dataset.customer = site.customer;
						N_Sites.appendChild(N_option);
					}
				}
			}
		});

		const N_add = N_container.querySelector('#add') as HTMLButtonElement;
		const N_delete = N_container.querySelector('#delete') as HTMLButtonElement;

		N_add.addEventListener2('click', () => {
			const options = N_Sites.querySelectorAll('option') as NodeListOf<HTMLOptionElement>;

			for (const option of options) {
				if (option.selected) {
					this.sitesSelected.set(option.value, {
						id: option.value,
						text: option.innerHTML,
						customer: option.dataset.customer
					});
				}
			}

			this.displaySelectedSites(customersDisplay);
		});

		N_delete.addEventListener2('click', () => {
			const options = N_select.querySelectorAll('option') as NodeListOf<HTMLOptionElement>;
			for (const option of options) {
				if (option.selected) {
					this.sitesSelected.delete(option.value);
				}
			}

			this.displaySelectedSites(customersDisplay);
		});

		this.displaySelectedSites(customersDisplay);
	}

	private displaySelectedSites(customersDisplay: { [key: string]: any }) {
		const N_container = this.element.querySelector('#container-sites') as HTMLElement;
		const N_select = N_container.querySelector('#select-sites') as HTMLSelectElement;

		N_select.innerHTML = '';

		const valuesByCustomer = _.groupBy([...this.sitesSelected.values()], 'customer') as { [key: string]: any[] };

		for (const customer in valuesByCustomer) {
			const N_optGroup = document.createElement('optgroup');
			N_optGroup.label = customersDisplay[customer];
			N_select.appendChild(N_optGroup);

			for (const site of (valuesByCustomer[customer] || [])) {
				const N_option = new Option(site.text, site.id);
				N_option.dataset.customer = customer;

				N_select.appendChild(new Option(site.text, site.id));
			}
		}
	}

	private async save() {
		const data = this.form!.getData();

		const endDate = data.endDate && data.endDate !== '' ? (data.endDate as Moment).format('x') : moment('9999-12-31', 'YYYY-MM-DD').format('x');

		const user = {
			_id: this.id,
			login: (data.login as string).trim(),
			password: (data.password as string).trim(),
			sites: data.privilege === 'all' ? [] : [...this.sitesSelected.keys()],
			endDate,
			privilege: data.privilege,
			allAccess: data.privilege === 'all'
		};

		await S_Qr_User.getInstance().save(user);
		this.resolve();
	}

	private initRadioBoxPrivileges() {
		const N_privilege_all = this.element.querySelector('#privilege_all') as HTMLInputElement;
		const N_privilege_sites = this.element.querySelector('#privilege_sites') as HTMLInputElement;

		N_privilege_all.addEventListener2('change', () => this.privilegeAll());
		N_privilege_sites.addEventListener2('change', () => this.privilegeSites());
	}

	private privilegeAll() {
		const N_sites = this.element.querySelector('#container-sites') as HTMLElement;

		N_sites.classList.add('d-none');

		const N_description = this.element.querySelector('#descriptionPrivilege small') as HTMLElement;
		N_description.textContent = 'Contient tous les autorisations, dont la creation/modification/suppression de Qrcode en via nos API.';
	}

	private privilegeSites() {
		const N_sites = this.element.querySelector('#container-sites') as HTMLElement;

		N_sites.classList.remove('d-none');

		const N_description = this.element.querySelector('#descriptionPrivilege small') as HTMLElement;
		N_description.textContent = 'Autorisations par sites.';
	}

	private initSearch(parent: HTMLElement, idSearch: string, idSelect: string) {
		const N_input = parent.querySelector(`#${idSearch}`) as HTMLInputElement;
		const N_select = parent.querySelector(`#${idSelect}`) as HTMLElement;

		N_input.addEventListener2('input', () => {
			const options = N_select.querySelectorAll('option') as NodeListOf<HTMLOptionElement>;
			for (const option of options) {
				if (option.innerHTML.toLowerCase().includes(N_input.value.toLowerCase())) {
					option.classList.remove('d-none');
				} else {
					option.classList.add('d-none');
				}
			}
		});
	}
}

export default UsersModal;
