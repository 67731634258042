import _ from 'lodash';

import Options from './GridOptions';

import Decimal from '@libs/utils/Decimal';

class UtilsQuotes {
	private constructor() { }

	private static calculatePriceV1(item: { [key: string]: any }, pref: { [key: string]: any }) {
		pref.price = pref.price || {};
		pref.marges = pref.marges || {};

		pref.coefTotal = pref.coefTotal || 1;

		const result = {
			price: 0,
			sumMat: 0,
			sumMO: 0,
			sum: 0
		};

		if (item.quantity && !item.disabled) {
			if (item.time && pref.price[item.type]) {
				let tmp = parseFloat(item.time) * pref.price[item.type];

				if (!item.notApplyCoefOnMO) {
					tmp *= pref.coefTotal;
				}

				tmp = UtilsQuotes.roundUp(tmp, pref);

				result.sumMO = UtilsQuotes.roundUp(tmp * item.quantity, pref);

				result.price += tmp;
			}

			if (item.price) {
				let tmp = 0;

				const marge = (pref.marges[(item.typeMat || '').trim()] || 0) / 100 + 1;

				tmp = item.price * marge;

				if (item.typeMat === Options.DISCOUNT) {
					tmp = UtilsQuotes.roundUp(tmp, pref);

					result.price -= tmp;

					result.sumMat = UtilsQuotes.roundUp(tmp * item.quantity * -1, pref);
				} else {
					if (!item.notApplyCoefOnMat) {
						tmp *= pref.coefTotal;
					}

					tmp = UtilsQuotes.roundUp(tmp, pref);

					result.price += tmp;

					result.sumMat = UtilsQuotes.roundUp(tmp * item.quantity, pref);
				}
			}
		}

		result.price = UtilsQuotes.round(result.price, pref);

		result.sum = UtilsQuotes.round(result.sumMO + result.sumMat, pref);

		return result;
	}

	private static calculatePriceV2(item: { [key: string]: any }, pref: { [key: string]: any }) {
		pref.price = pref.price || {};
		pref.marges = pref.marges || {};

		pref.coefTotal = pref.coefTotal || '1';

		pref.coefTotal = pref.coefTotal.toString().replace(',', '.');

		const result = {
			price: 0,
			sumMat: 0,
			sumMO: 0,
			sum: 0
		};

		if (item.quantity && !item.disabled) {
			const type = item.type || pref.type || '';

			if (item.time && pref.price[type]) {
				item.time = item.time.toString().replace(',', '.');

				let tmp = parseFloat(item.time) * pref.price[type];

				if (!item.notApplyCoefOnMO) {
					tmp *= pref.coefTotal;
				}

				result.sumMO = tmp * item.quantity;
			}

			if (item.price) {
				let tmp = 0;
				let marge = 0;

				const typeMat = (item.typeMat || pref.typeMat || '').trim();
				item.price = item.price.toString().replace(',', '.');

				// Si on applique une marge commerciale
				if (pref.calculMarkUp) {
					// Marge commerciale = 1 - (taux marge / 100)
					marge = 1 - ((pref.marges[typeMat] || 0) / 100);

					// Caclul prix avec marge commerciale = Prix / Marge commerciale = prix / ( 1 - (taux marge / 100))
					tmp = parseFloat(item.price) / marge;
				} else { // sinon on applique un coef simple
					marge = (pref.marges[typeMat] || 0) / 100 + 1;
					tmp = parseFloat(item.price) * marge;
				}

				if (typeMat === Options.DISCOUNT) {
					result.sumMat = tmp * item.quantity * -1;
				} else {
					if (!item.notApplyCoefOnMat) {
						tmp *= pref.coefTotal;
					}

					result.sumMat = tmp * item.quantity;
				}
			}
		}

		result.sumMat = UtilsQuotes.round(result.sumMat, {
			decimalNumber: 2
		});

		result.sumMO = UtilsQuotes.round(result.sumMO, {
			decimalNumber: 2
		});

		result.sum = UtilsQuotes.round(result.sumMO + result.sumMat, pref);

		result.price = UtilsQuotes.round(result.sum / item.quantity, {
			decimalNumber: 2
		});

		return result;
	}

	public static calculatePrice(item: { [key: string]: any }, pref: { [key: string]: any }) {
		let result: { price: number, sumMat: number, sumMO: number, sum: number };
		if (window.location.hash.includes('V1?id')) {
			result = UtilsQuotes.calculatePriceV1(item, pref);
		} else {
			result = UtilsQuotes.calculatePriceV2(item, pref);
		}

		//on recalcul la somme MO pour masquer les problèmes dû a l'arrondi
		const percentMO = 100 * result.sumMO / (result.sumMO + result.sumMat);

		result.sumMO = UtilsQuotes.round(result.sum * percentMO / 100, pref);
		result.sumMat = UtilsQuotes.round(result.sum - result.sumMO, pref);

		//sécurité pour eviter les NaN (division par 0)
		result.sumMat = isNaN(result.sumMat) ? 0 : result.sumMat;
		result.sumMO = isNaN(result.sumMO) ? 0 : result.sumMO;
		result.sum = isNaN(result.sum) ? 0 : result.sum;
		result.price = isNaN(result.price) ? 0 : result.price;

		return result;
	}

	public static roundUp(numberStr: string | number, pref: { [key: string]: any } = {}): number {
		pref.decimalNumber = _.isUndefined(pref.decimalNumber) ? 2 : pref.decimalNumber;

		const pow = Math.pow(10, pref.decimalNumber);

		let number = parseFloat(numberStr.toString());
		number = Math.ceil(parseFloat((number * pow).toFixed(1)));
		number = parseFloat((number / pow).toString());

		return number;
	}

	public static round(numberStr: string | number, pref: { [key: string]: any }): number {
		pref.decimalNumber = _.isUndefined(pref.decimalNumber) ? 2 : pref.decimalNumber;

		const pow = Math.pow(10, pref.decimalNumber);

		let number = parseFloat(numberStr.toString());
		number = Math.round(number * pow);
		number = parseFloat((number / pow).toString());

		return number;
	}

	public static humanizeNumber(number: string, pref: { [key: string]: any }, ignoreRound = false) {
		if (ignoreRound) {
			number = parseFloat(number).toString();
		} else {
			number = UtilsQuotes.roundUp(number, pref).toString();
		}

		if (isNaN(parseFloat(number))) {
			return '';
		} else {
			let result = '';

			const chunkNumber = number.split('.');

			const tmp = chunkNumber[0].split('').reverse();

			for (let i = tmp.length - 1; i >= 0; i--) {
				result += tmp[i];

				if (i % 3 === 0 && i !== 0) {
					result += ' ';
				}
			}

			chunkNumber[1] = chunkNumber[1] || '';

			if (ignoreRound) {
				if (chunkNumber[1].length) {
					result = result + '.' + chunkNumber[1];
				}
			} else {
				if (pref.decimalNumber && pref.decimalNumber !== '0') {
					result = result + '.' + (chunkNumber[1] + '00').slice(0, pref.decimalNumber);
				}
			}

			return result;
		}
	}

	public static calculateFooterDetails(params: any, pref = {}) {
		const rowPinned = {
			time: new Decimal(0),
			km: new Decimal(0),
			sumMaterials: new Decimal(0),
			price: new Decimal(0),
			marges: new Decimal(0)
		};

		const rows: { [key: string]: any }[] = [];

		params.api.forEachNode((node: any) => {
			rows.push(node.data);
		});

		for (const item of rows) {
			const quantity = Decimal.setDisplayNumber(item.quantity);

			const time = Decimal.setDisplayNumber(item.time).times(quantity);
			const price = Decimal.setDisplayNumber(item.price).times(quantity);
			const costPrice = Decimal.setDisplayNumber(item.costPrice).times(quantity);

			const data = UtilsQuotes.calculatePrice(item, pref);

			const sumMat = Decimal.setDisplayNumber(data.sumMat);

			let marge = new Decimal(0);

			if (item.reference) {
				marge = sumMat.minus(costPrice);
			} else {
				marge = sumMat.minus(price);
			}

			if (item.type === Options.KILOMETRAGE_COST) {
				rowPinned.km = rowPinned.km.plus(time);
			} else {
				rowPinned.time = rowPinned.time.plus(time);
			}

			if (item.typeMat === Options.LICENCE) {
				rowPinned.marges = rowPinned.marges.plus(price);
			} else if (item.typeMat === Options.DISCOUNT) {
				rowPinned.marges = rowPinned.marges.minus(price);
			} else {
				rowPinned.price = rowPinned.price.plus(price);
				rowPinned.marges = rowPinned.marges.plus(marge);
				rowPinned.sumMaterials = rowPinned.sumMaterials.plus(sumMat);
			}
		}

		return rowPinned;
	}

	public static calculateGlobalPrice(grid: any[], pref: { [key: string]: any } = {}) {
		const data = {
			all: 0,
			option: 0,
			notOption: 0
		};

		for (const group of grid) {
			let price = 0;

			if (!group.disabled) {
				for (const item of group.details) {
					price += UtilsQuotes.calculatePrice(item, pref).sum;
				}
			}

			const tmp = (price * (group.quantity || 0));

			data.all += tmp;

			if (group.isOption) {
				data.option += tmp;
			} else {
				data.notOption += tmp;
			}
		}

		return {
			all: UtilsQuotes.roundUp(data.all, pref),
			option: UtilsQuotes.roundUp(data.option, pref),
			notOption: UtilsQuotes.roundUp(data.notOption, pref)
		};
	}
}

export default UtilsQuotes;
