import { Module, Modules, Router } from '@autoprog/core-client';

import C_Module from './js/controllers/Tools';

import T_module from './tpl/tools.html';

import Navigation from '@libs/Navigation';

class ToolsModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);
	}

	public async preInit() {
		const navigation = Navigation.getInstance();
		navigation.addCategory({
			name: 'Outils',
			order: 200
		});
		navigation.addLink({
			order: 0,
			category: 'Outils',
			name: 'Outils',
			type: 'module',
			module: 'tools',
			permissions: ['TOOLS'],
			icon: 'tools/line'
		});
	}

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: '/module/tools',
			controller: C_Module as any,
			tpl: T_module,
			requireValidUser: true
		});
	}

	public async postInit() {

	}
}

Modules.registerModule('Outils', ToolsModule);
