import { ContextMenu, Tab, global } from '@autoprog/core-client';

import _ from 'lodash';

import axios from 'axios';

import M_EditIcon from '../modals/EditIcon';

class IconTab extends Tab {
	private icons: string[] = [];
	private dataIcons: any[] = [];
	private el: HTMLElement;
	private N_search: HTMLInputElement;

	constructor(el: HTMLElement) {
		super(el);

		this.el = el;

		this.icons = this.getAllIcons();

		this.N_search = document.querySelector('#search') as HTMLInputElement;

		axios.get(`${global.COUCHDB_URL}/autoprog_icons/_all_docs`, {
			params: {
				include_docs: true
			}
		}).then(({ data }) => {
			this.dataIcons = _.map(data.rows, 'doc');

			this.displayIcon();

			const N_containerInputSearch = document.querySelector('#container-input-search') as HTMLElement;
			N_containerInputSearch.classList.remove('d-none');
			this.N_search.addEventListener2('keyup', () => {
				this.search();
			});
		});
	}

	private search() {
		const N_icons = this.el.querySelectorAll('[data-search]') as NodeListOf<HTMLElement>;

		if (!this.N_search.value) {
			N_icons.forEach((N_el) => {
				N_el.classList.remove('d-none');
			});
		} else {
			N_icons.forEach((N_el) => {
				if ((N_el.dataset.search || '').includes(this.N_search.value)) {
					N_el.classList.remove('d-none');
				} else {
					N_el.classList.add('d-none');
				}
			});
		}
	}

	private getAllIcons() {
		const icons: string[] = [];
		const regexIcon = /^\.icon-([\w_-]+)::before$/;

		for (let i = 0; i < document.styleSheets.length; i++) {
			const css = document.styleSheets[i] as CSSStyleSheet;

			for (let j = 0; j < css.rules.length; j++) {
				const rule = css.rules[j] as CSSStyleRule;

				if (rule.selectorText) {
					const match = rule.selectorText.match(regexIcon);

					if (match) {
						icons.push(match[1]);
					}
				}
			}
		}

		return icons;
	}

	private displayIcon() {
		const copyStatus = document.querySelector('.copyStatus') as HTMLElement;
		const copyTextarea = document.querySelector('.js-copytextarea') as HTMLTextAreaElement;
		const N_container = this.el.querySelector('#icons-container') as HTMLElement;

		N_container.innerHTML = '';

		for (const icon of this.icons) {
			const number = 1;//this.specificCol[icon] || 1;

			const N_div = document.createElement('div');

			N_div.classList.add('border', 'border-dark', 'text-center', 'p-2', 'position-relative');

			N_div.style.minWidth = (100 * number) + 'px';

			const data = (_.find(this.dataIcons, { _id: icon } as any) || {}) as { [key: string]: any };

			data.tags = data.tags || [];

			data.tags.push(icon);

			N_div.dataset.search = data.tags.join(',');

			N_div.innerHTML = `
                <i class="icon icon-${icon}" style="font-size:50px"></i>
                <div class="text-xs">
                    ${icon}
                </div>
                <i class="icon icon-solid-edit position-absolute" id="edit" style="top:-2px; left:3px;"></i>
            `;

			N_div.addEventListener2('click', (e) => {
				if ((e.target as HTMLElement).id !== 'edit') {
					const bBox = N_div.getBoundingClientRect();

					//On récupère le textarea de copie, on définit sa valeur et on sélectionne tous le texte
					copyTextarea.value = `icon icon-${icon}`;
					copyTextarea.select();

					//On positionne le status du copie devant la tuile de l'icon
					copyStatus.style.top = window.scrollY + bBox.top + 'px';
					copyStatus.style.left = window.scrollX + bBox.left + 'px';
					copyStatus.style.width = bBox.width + 'px';
					copyStatus.style.height = bBox.height + 'px';
					copyStatus.style.lineHeight = bBox.height + 'px';

					try {
						//On remplit le press-papier
						if (document.execCommand('copy')) { //En cas de success
							copyStatus.innerHTML = '✔️';
							copyStatus.className = 'copyStatus bg-success show';
						} else { //En cas d'erreur
							copyStatus.innerHTML = '❌';
							copyStatus.className = 'copyStatus bg-danger show';
						}
					} catch (err) { //En cas d'erreur
						console.error(err);
						copyStatus.innerHTML = '❌';
						copyStatus.className = 'copyStatus bg-danger show';
					}

					setTimeout(function () {
						copyStatus.classList.add('hide');

						setTimeout(function () {
							copyStatus.style.top = -10000 + 'px';
						}, 500);
					}, 1000);
				}
			});

			const N_edit = N_div.querySelector('#edit') as HTMLElement;

			N_edit.addEventListener2('click', () => {
				new M_EditIcon(icon).open().then(async () => {
					const data = (await axios.get(`${global.COUCHDB_URL}/autoprog_icons/_all_docs`, {
						params: {
							include_docs: true
						}
					})).data;

					this.dataIcons = _.map(data.rows, 'doc');

					this.displayIcon();

					this.search();
				});
			});

			ContextMenu.addForElement(N_div, [{
				name: `Modification tags ( ${icon} )`,
				action: () => {
					new M_EditIcon(icon).open().then(async () => {
						const data = (await axios.get(`${global.COUCHDB_URL}/autoprog_icons/_all_docs`, {
							params: {
								include_docs: true
							}
						})).data;

						this.dataIcons = _.map(data.rows, 'doc');

						this.displayIcon();

						this.search();
					});
				}
			}]);

			N_container.append(N_div);
		}
	}

	public destructor() {
		const N_containerInputSearch = document.querySelector('#container-input-search') as HTMLElement;

		N_containerInputSearch.classList.add('d-none');
	}
}

export default IconTab;
