import { Form, Modal } from '@autoprog/core-client';

import _ from 'lodash';
import h from 'hyperscript';

import T_AddEditRound from '../../tpl/modals/addEditRound.html';

import Game from '../libs/Game';
import { Round } from '../services/GamesService';
import getTopPlayerCount from '../libs/getTopPlayerCount';

class AddEditRound extends Modal {
	private game: Game;
	private form?: Form;
	private roundIndex: number;
	private N_playerList?: HTMLElement;
	public constructor(game: Game, roundIndex?: number) {
		super({
			tpl: T_AddEditRound,
			backdrop: 'static'
		});

		this.roundIndex = (roundIndex || roundIndex === 0) ? roundIndex : -1;
		this.game = game;

		this.on('opened', async () => {
			const players = this.game.getPlayers();

			/* -------------------------------------------------------------------------- */
			/*                              Gestion du visuel                             */
			/* -------------------------------------------------------------------------- */

			this.N_playerList = this.element.querySelector('#players-list') as HTMLUListElement;
			const N_roundForm = this.element.querySelector('#round-form') as HTMLFormElement;
			this.form = new Form(N_roundForm);

			//Creation de la liste des joueurs
			for (const player of players.values()) {
				this.N_playerList.appendChild(h('li.list-group-item.d-flex.justify-content-between.align-items-center', { attrs: { 'data-id': `p_${player._id}` } },
					h('span', `${player.lastname[0]}. ${player.firstname}`),
					h('input.form-control.w-50', { attrs: { type: 'number', name: `p_${player._id}` } })
				));
			}

			//Actualisation des inputs des scores
			this.form.updateInputs();

			/* -------------------------------------------------------------------------- */
			/*                       Mise en évidance du classement                       */
			/* -------------------------------------------------------------------------- */

			this.form.on('**.change', () => {
				this.updateRankColors();
			});

			/* -------------------------------------------------------------------------- */
			/*                  Remplissage du formulaire en cas d'édition                */
			/* -------------------------------------------------------------------------- */

			if (this.roundIndex >= 0) {
				const round = this.game.getRound(this.roundIndex);
				for (const pID in round) {
					round['p_' + pID] = round[pID];
					delete round[pID];
				}
				this.form.setData(round);
				this.updateRankColors();
			}
		});
	}

	private updateRankColors() {
		if (this.form && this.N_playerList) {
			const scores = this.form.getData();
			const players = this.game.getPlayers();

			for (const pID in scores) {
				//si les inputs sont vide, la valeur est NaN. On la force donc a -1
				if (isNaN(scores[pID] as number)) {
					scores[pID] = -1;
				}
			}

			const ranks = _.sortBy(_.toPairs(scores), 1).reverse();

			const topPlayerCount = getTopPlayerCount(players.size);

			//Supression des classes
			for (let i = 0; i < ranks.length; i++) {
				const pID = ranks[i][0];
				const score = ranks[i][1] as number;

				const N_playerRankEL = this.N_playerList.querySelector(`li[data-id="${pID}"]`);
				if (N_playerRankEL) {
					N_playerRankEL.className = N_playerRankEL.className.replace(/rank-([\w-]*)/g, '');
					N_playerRankEL.className = N_playerRankEL.className.replace(/order-([\d]*)/g, '');
					N_playerRankEL.classList.add(`order-${i}`);

					if (score >= 0) {
						//Rang de premier
						if (i < topPlayerCount) {
							N_playerRankEL.classList.add(`rank-winner-${i + 1}`);
						}

						//Rang de dernier
						if (i >= ranks.length - topPlayerCount) {
							N_playerRankEL.classList.add(`rank-looser-${ranks.length - i}`);
						}
					}
				}
			}
		}
	}

	public async resolve() {
		if (this.form && this.game) {
			const data = this.form.getData() as Round;

			//Supression du p_ des données
			for (const i in data) {
				if (!isNaN(data[i])) {
					data[i.replace('p_', '')] = data[i];
				}
				delete data[i];
			}

			if (this.roundIndex >= 0) {
				await this.game.setRound(this.roundIndex, data);
			} else {
				await this.game.addRound(data);
			}

			super.resolve();
		}
	}
}

export default AddEditRound;
