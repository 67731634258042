import { Controller } from '@autoprog/core-client';

import h from 'hyperscript';

import EditGame from '../modals/EditGame';
import Game from '../libs/Game';

import GamesService from '../services/GamesService';
import PlayersService from '../services/PlayersService';
import moment from 'moment';

class PresidentCtrl extends Controller {
	private N_el: HTMLElement;

	constructor(el: HTMLElement) {
		super(el);

		this.N_el = el;

		const N_addBtn = el.querySelector('#add-game-btn') as HTMLButtonElement;

		N_addBtn.addEventListener2('click', async () => {
			const game = new Game();
			await game.init();

			try {
				await new EditGame(game).open();
			} catch (e) {

			}

			this.loadData();
		});

		this.init();
	}

	private async init() {
		await this.loadData();
	}

	private async loadData() {
		const gamesService = new GamesService();
		const playersService = new PlayersService();
		const games = await gamesService.getAll();
		const playersIds: Set<string> = new Set();

		const N_gamesTableContainer = this.N_el.querySelector('#games-tables-container') as HTMLElement;
		N_gamesTableContainer.innerHTML = '';

		for (const game of games) {
			for (const pID of game.players) {
				playersIds.add(pID);
			}
		}
		const players = new Map();
		const ps = await playersService.getByIDs(Array.from(playersIds.values()));
		for (const p of ps) {
			players.set(p._id, p);
		}

		for (const game of games) {
			const N_gamesTable = h('table.table.table-sm.table-bordered.table-striped.text-center') as HTMLTableElement;
			N_gamesTableContainer.append(N_gamesTable);

			const N_editBtn = h('button.btn.btn-xs.btn-info.float-right', h('i.icon.icon-solid-pen'));
			N_editBtn.addEventListener2('click', async () => {
				const g = new Game(game._id);
				await g.init();
				new EditGame(g).open();
			});

			N_gamesTable.appendChild(
				h('thead.thead-light',
					h('tr',
						h('th', { attrs: { colspan: game.players.length } }, moment(game._id, 'YYYY-MM-DD-HH-mm-ss').format('DD/MM/YYYY'), N_editBtn)

					),
					h('tr',
						game.players.map((pID: string) => {
							const player = players.get(pID);
							return h('th', player.firstname.slice(0, 2));
						})

					)
				)
			);

			if (game.rounds) {
				for (const round of game.rounds) {
					const N_row = N_gamesTable.insertRow();
					for (const pID of game.players) {
						N_row.appendChild(h('td', round[pID]));
					}
				}
			}
		}
	}

	public destructor() {

	}
}

export default PresidentCtrl;
