import { global } from '@autoprog/core-client';

import _ from 'lodash';
import axios from 'axios';

export type Round = { [key: string]: number };

export type Game = {

	_id: string,
	_rev?: string,
	players: string[]
	rounds: Round[]

};

class GamesService {
	public async getAll(): Promise<Game[]> {
		const users = (await axios.get(`${global.COUCHDB_URL}/autoprog_games/_design/all/_view/byID`, {
			params: {
				include_docs: true
			}
		})).data;

		return _.map(users.rows, 'doc');
	}

	public async getByID(gameID: string): Promise<Game> {
		const res = await axios.get(`${global.COUCHDB_URL}/autoprog_games/${gameID}`);
		return res.data;
	}

	public async update(game: Game): Promise<string> {
		const res = (await axios.put(`${global.COUCHDB_URL}/autoprog_games/${game._id}`, game));

		if (!res.data.ok) {
			throw new Error('not_ok');
		}
		return res.data.rev;
	}
}

export default GamesService;
