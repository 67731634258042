import { Modal } from '@autoprog/core-client';

import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

import T_modal from '../../tpl/modals/recapMonthByUser.html';

import _ from 'lodash';
import moment from 'moment';

import Utils from '../libs/UtilsHours';
import UtilsRecap from '../libs/UtilsRecap';

import S_Hours from '@services/Hours/HoursService';
import S_TravelsDetails from '@services/Hours/TravelsDetailsService';

class RecapByUser extends Modal {
	private month: string = '';
	private year: string = '';
	private user: { [key: string]: any } = {};
	private travelsDetails: { [key: string]: any }[] = [];
	private holidaysData: { [key: string]: any }[] = [];

	constructor(users: { [key: string]: any }[], index: number, month: string, year: string) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.user = users[index];
		this.month = month;
		this.year = year;

		this.on('opened', async () => {
			const gridOptions: GridOptions = {
				columnDefs: [
					{
						headerName: 'Sem.',
						field: 'week',
						width: 60,
						cellStyle: (params) => {
							return {
								background: params.data.background
							};
						},
						suppressSizeToFit: true
					},
					{
						headerName: 'Jour',
						field: 'day',
						width: 80,
						colSpan: (params) => {
							return params.data.type === 'fullLine' ? 3 : 1;
						},
						cellRenderer: (params) => {
							if (params.data.type === 'fullLine' && params.data.date) {
								const N_div = document.createElement('div');
								N_div.innerHTML = `
                                    Du : ${params.data.date.clone().startOf('week').format('DD/MM/YYYY')} Au : ${params.data.date.clone().endOf('week').format('DD/MM/YYYY')}
									<button class="btn btn-success  d-none" id="add">
										<i class="icon icon-solid-plus"></i>
									</button>
									<button class="btn btn-danger d-none" id="minus">
										<i class="icon icon-solid-plus"></i>
									</button>
                                `;

								const N_add = N_div.querySelector('#add') as HTMLButtonElement;
								const N_minus = N_div.querySelector('#minus') as HTMLButtonElement;

								N_add.addEventListener2('click', () => {
									//TODO
								});

								N_minus.addEventListener2('click', () => {
									//TODO
								});

								return N_div;
							} else {
								return params.value;
							}
						},
						suppressSizeToFit: true
					},
					{
						headerName: 'Vacances',
						field: 'hours.holiday',
						width: 50,
						suppressSizeToFit: true,
						cellRenderer: (params) => {
							if (params.data.type !== 'fullLine') {
								return _.uniq(params.value || []).join(', ');
							}

							return '';
						}
					},
					{
						headerName: 'Location',
						field: 'hours.location',
						width: 200,
						suppressSizeToFit: true,
						cellRenderer: (params) => {
							if (params.data.type !== 'fullLine') {
								return (params.value || []).join(', ');
							}

							return '';
						}
					},
					{
						headerName: 'Heures',
						field: 'hours',
						width: 300,
						suppressSizeToFit: true,
						cellRenderer: (params) => {
							if (params.data.type !== 'fullLine') {
								const N_div = document.createElement('div');

								params.value = params.value || {};

								let hoursFerie = 0;
								const date = moment(params.value.date, 'DD/MM/YYYY');

								//@ts-ignore
								if ((date.isFerie() && date.getFerie() !== 'Pentecôte') && [1, 2, 3, 4, 5].includes(date.day())) {
									hoursFerie = 7 * 60;
								}

								let workTime = UtilsRecap.roundTime((params.value.workTime || 0) + hoursFerie);
								const tripTime = UtilsRecap.roundTime(params.value.tripTime || 0);

								workTime -= tripTime;

								N_div.innerHTML = `
                                    <div class="d-inline-block" style="width:150px" tooltip="Valeur avant arrondi : ${Utils.convertMinToString(params.value.workTime - params.value.tripTime || 0)} ${hoursFerie ? ' + Férié = 7h' : ''}">
                                        <span class="">Travail : </span>${Utils.convertMinToString(workTime)}
                                    </div>
                                    <div class="d-inline-block" style="width:150px" tooltip="Valeur avant arrondi : ${Utils.convertMinToString(params.value.tripTime || 0)}">
                                        <span class="ml-2">Trajet : </span>${Utils.convertMinToString(tripTime)}
                                    </div>
                                `;

								return N_div;
							} else {
								const N_div = document.createElement('div');

								let workTime = 0;
								let tripTime = 0;

								for (const item of params.data.hoursWeek) {
									let hoursFerie = 0;
									const date = moment(item.date, 'DD/MM/YYYY');

									//@ts-ignore
									if ((date.isFerie() && date.getFerie() !== 'Pentecôte') && [1, 2, 3, 4, 5].includes(date.day())) {
										hoursFerie = 7 * 60;
									}

									workTime += UtilsRecap.roundTime((item.workTime || 0) + hoursFerie);
									tripTime += UtilsRecap.roundTime(item.tripTime || 0);
								}

								workTime -= tripTime;

								N_div.innerHTML = `
                                    <div class="d-inline-block" style="width:150px">
                                        <span class="font-weight-bold">Travail : </span>${Utils.convertMinToString(workTime)}
                                    </div>
                                    <div class="d-inline-block" style="width:150px">
                                        <span class="ml-2 font-weight-bold">Trajet : </span>${Utils.convertMinToString(tripTime)}
                                    </div>
                                `;

								return N_div;
							}
						}
					},
					{
						headerName: 'Déplacement',
						cellRenderer: (params) => {
							if (params.data.type !== 'fullLine') {
								const disabled = moment(params.data.date, 'DD/MM/YYYY').format('MM') !== this.month ? 'disabled="true"' : '';

								const tmp = this.getTravelByDate(params.data.date);

								const N_div = document.createElement('div');

								N_div.innerHTML = `
                                    <div class="d-inline-flex"> 
                                        <div class="mr-3">
                                            <input type="number" class="mr-1" style="max-width: 50px" name="mealLittleTravel" value="${tmp.mealLittleTravel || 0}" ${disabled}>
                                            Panier Repas
                                        </div>
                                        <div class="mr-3">
                                            <input type="checkbox" name="morning" ${tmp.morning ? 'checked' : ''} ${disabled}>
                                            Petit déj
                                        </div>
                                        <div class="mr-3">
                                            <input type="checkbox" name="midday" ${tmp.midday ? 'checked' : ''} ${disabled}>
                                            Repas midi
                                        </div>
                                        <div class="mr-3">
                                            <input type="checkbox" name="evening" ${tmp.evening ? 'checked' : ''} ${disabled}>
                                            Repas soir
                                        </div>
                                        <div class="mr-3">
                                            <input type="checkbox" name="night" ${tmp.night ? 'checked' : ''} ${disabled}>
                                            Nuit
                                        </div>
                                        <div class="mr-3">
                                            <input type="checkbox" name="nightIDF" ${tmp.nightIDF ? 'checked' : ''} ${disabled}>
                                            Nuit IDF
                                        </div>
                                        <div class="mr-3">
                                            Km : 
                                            <input style="max-width: 100px" type="number" name="km" value="${tmp.km || 0}"/ ${disabled}>
                                        </div>
										${disabled ? '<i class="icon icon-solid-lock"></i>' : ''}
                                    </div>
                                `;

								const N_inputs = N_div.querySelectorAll('input') as NodeListOf<HTMLInputElement>;

								N_inputs.forEach((N_input) => {
									const name = N_input.name;

									N_input.addEventListener2('change', async () => {
										const id = `${this.user._id}_${moment(params.data.date, 'DD/MM/YYYY').format('YYYY_MM_DD')}`;

										const data: { [key: string]: any } = {
											date: params.data.date
										};

										data._id = id;
										data.user = this.user._id;

										if (name === 'km' || name === 'mealLittleTravel') {
											data[name] = N_input.value;
										} else {
											data[name] = N_input.checked ? 1 : 0;
										}

										await S_TravelsDetails.getInstance().save(data);

										await this.getData();
									});
								});

								return N_div;
							}

							return '';
						}
					}
				],
				suppressRowTransform: true,
				suppressContextMenu: true,
				defaultColDef: {
					suppressMenu: true,
					resizable: true
				},
				getRowStyle: (params: any) => {
					if (params.data.type !== 'fullLine') {
						const date = moment(params.data.date, 'DD/MM/YYYY');

						//@ts-ignore
						if (date.isFerie() && date.getFerie() !== 'Pentecôte') {
							return {
								background: '#e5b07e'
							};
						}

						if (date.day() === 0) {
							return {
								background: '#ef9a9a'
							};
						}
					}

					if (params.data.type === 'fullLine' && params.data.date) {
						return {
							background: params.data.background,
							'font-weight': 'bold'
						};
					}

					return {
						background: ''
					};
				},
				onGridReady: async (params) => {
					const data = await this.getData();

					params.api.setRowData(data);

					params.api.sizeColumnsToFit();
				}
			};

			const N_grid = this.element.querySelector('#grid') as HTMLElement;

			new Grid(N_grid, gridOptions, { modules: AllModules });

			this.updateButton(users, index);

			const N_next = this.element.querySelector('#next') as HTMLButtonElement;
			const N_prev = this.element.querySelector('#prev') as HTMLButtonElement;

			N_next.addEventListener2('click', async () => {
				N_next.disabled = true;

				N_next.loading(new Promise<void>(async (resolve) => {
					index++;

					if (index > users.length - 1) {
						index = 0;
					}

					this.user = users[index];

					this.updateButton(users, index);

					const data = await this.getData();

					gridOptions.api?.setRowData(data);

					gridOptions.api?.sizeColumnsToFit();

					N_next.disabled = false;

					resolve();
				}));
			});

			N_prev.addEventListener2('click', async () => {
				N_prev.disabled = true;

				N_prev.loading(new Promise<void>(async (resolve) => {
					index--;

					if (index < 0) {
						index = users.length - 1;
					}

					this.user = users[index];

					this.updateButton(users, index);

					const data = await this.getData();

					gridOptions.api?.setRowData(data);

					gridOptions.api?.sizeColumnsToFit();

					N_prev.disabled = false;

					resolve();
				}));
			});

			const N_next_month = this.element.querySelector('#next_month') as HTMLButtonElement;
			const N_prev_month = this.element.querySelector('#prev_month') as HTMLButtonElement;

			N_next_month.addEventListener2('click', async () => {
				N_next_month.disabled = true;

				N_next_month.loading(new Promise<void>(async (resolve) => {
					const date = moment(`${this.month}_${this.year}`, 'MM_YYYY').add(1, 'month');

					this.month = date.format('MM');
					this.year = date.format('YYYY');

					const data = await this.getData();

					gridOptions.api?.setRowData(data);

					gridOptions.api?.sizeColumnsToFit();

					N_next_month.disabled = false;

					resolve();
				}));
			});

			N_prev_month.addEventListener2('click', async () => {
				N_prev_month.disabled = true;

				N_prev_month.loading(new Promise<void>(async (resolve) => {
					const date = moment(`${this.month}_${this.year}`, 'MM_YYYY').subtract(1, 'month');

					this.month = date.format('MM');
					this.year = date.format('YYYY');

					const data = await this.getData();

					gridOptions.api?.setRowData(data);

					gridOptions.api?.sizeColumnsToFit();

					N_prev_month.disabled = false;

					resolve();
				}));
			});
		});
	}

	private updateButton(users: any[], index: number) {
		const N_next = this.element.querySelector('#next') as HTMLElement;
		const N_prev = this.element.querySelector('#prev') as HTMLElement;

		let nextIndex = index + 1;
		let prevIndex = index - 1;

		if (nextIndex > users.length - 1) {
			nextIndex = 0;
		}

		if (prevIndex < 0) {
			prevIndex = users.length - 1;
		}

		N_next.setAttribute('tooltip', users[nextIndex].name);
		N_prev.setAttribute('tooltip', users[prevIndex].name);
	}

	private async getData() {
		const N_title = this.element.querySelector('#month') as HTMLElement;

		N_title.innerHTML = `${this.user.name} | ${this.month}/${this.year}`;

		const dateMonth = moment(`${this.month}_${this.year}`, 'MM_YYYY');

		const startDate = dateMonth.clone().startOf('month').startOf('week');
		const endDate = dateMonth.clone().endOf('month').endOf('week');

		const date = startDate.clone();

		const { data } = await S_Hours.getInstance().getDataToModal(`${this.year}_${this.month}`, {
			type: 'month',
			user: this.user._id
		});

		this.travelsDetails = data.travelsDetails;
		this.holidaysData = data.holidays;

		this.updateTitle();

		const hours = await this.getHours(data.hours);

		const result: any[] = [];

		while (date.isSameOrBefore(endDate)) {
			let rowSpan = 0;

			if (date.day() === 1) {
				rowSpan = 7;
			} else if (date.isSame(startDate)) {
				rowSpan = 7 - (date.day() - 1);
			}

			if (rowSpan) {
				const startWeek = date.clone().startOf('week');
				const endWeek = date.clone().endOf('week');

				const hoursWeek: any[] = [];

				while (startWeek.isSameOrBefore(endWeek)) {
					const hoursResult = hours[startWeek.format('DD/MM/YYYY')] || {};
					hoursResult.date = startWeek.format('DD/MM/YYYY');

					hoursWeek.push(hoursResult);

					startWeek.add('1', 'day');
				}

				result.push({
					rowSpan: rowSpan + 2,
					week: 'S.' + date.format('WW'),
					background: Number(date.format('WW')) % 2 ? 'var(--ap-grey-100)' : 'var(--ap-grey-300)',
					date: date.clone(),
					hoursWeek,
					type: 'fullLine'
				});
			}

			const hoursResult = hours[date.format('DD/MM/YYYY')] || {};
			hoursResult.date = date.format('DD/MM/YYYY');

			result.push({
				day: date.format('dd').toUpperCase()[0] + ' ' + date.format('DD'),
				date: date.format('DD/MM/YYYY'),
				hours: hoursResult,
				background: Number(date.format('WW')) % 2 ? 'var(--ap-grey-100)' : 'var(--ap-grey-300)'
			});

			date.add(1, 'day');
		}

		return result;
	}

	private async getHours(hours: any[]) {
		const result: { [key: string]: any } = {};

		const data = _.groupBy(hours, 'date');

		for (const date in data) {
			const group = data[date];

			for (const item of group) {
				result[item.date] = result[item.date] || {};

				if (item.startHours && item.endHours && !item.idHolidays) {
					let workTime = 0;
					let tripTime = 0;

					const startDate = moment(`${item.date} ${item.startHours}`, 'DD/MM/YYYY HH:mm');
					const endDate = moment(`${item.date} ${item.endHours}`, 'DD/MM/YYYY HH:mm');

					workTime += endDate.diff(startDate, 'minute');

					if (item.isTravel) {
						tripTime += Utils.calculTimeTrip(item.date, startDate, endDate);
					}

					result[item.date].location = result[item.date].location || [];
					result[item.date].location.push(item.location);
					result[item.date].location = _.compact(_.uniq(result[item.date].location));

					result[item.date].tripTime = result[item.date].tripTime || 0;
					result[item.date].workTime = result[item.date].workTime || 0;

					result[item.date].tripTime += tripTime;
					result[item.date].workTime += workTime;
				}

				if (item.idHolidays) {
					const holiday = _.find(this.holidaysData, { _id: item.idHolidays });

					if (holiday) {
						result[item.date].holiday = result[item.date].holiday || [];

						//Congés
						if (holiday.infos.type === '0') {
							result[item.date].holiday.push('V');
						}

						//Recup
						if (holiday.infos.type === '1') {
							result[item.date].holiday.push('R');
						}

						//Absence
						if (holiday.infos.type === '2') {
							result[item.date].holiday.push('A');
						}

						//Arrêt Maladie
						if (holiday.infos.type === '3') {
							result[item.date].holiday.push('M');
						}
					}
				}
			}
		}

		return result;
	}

	private getTravelByDate(dateStr: string) {
		return _.find(this.travelsDetails, { date: dateStr }) || {};
	}

	private updateTitle() {
		const N_nightIDF = this.element.querySelector('#nightIDF') as HTMLElement;
		const N_night = this.element.querySelector('#night') as HTMLElement;
		const N_morning = this.element.querySelector('#morning') as HTMLElement;
		const N_meal = this.element.querySelector('#meal') as HTMLElement;
		const N_mealLittleTravel = this.element.querySelector('#mealLittleTravel') as HTMLElement;
		const N_km = this.element.querySelector('#km') as HTMLElement;

		const result = {
			nightIDF: 0,
			night: 0,
			morning: 0,
			meal: 0,
			mealLittleTravel: 0,
			km: 0
		};

		let index = 0;
		for (const item of this.travelsDetails) {
			if (item._id.includes(`${this.year}_${this.month}`)) {
				result.nightIDF += (item.nightIDF || 0);
				result.night += (item.night || 0);
				result.meal += (item.midday || 0) + (item.evening || 0);
				result.mealLittleTravel += Number(item.mealLittleTravel || 0);
				result.km += Number(item.km || 0);

				if (!this.travelsDetails[index - 1] || (!this.travelsDetails[index - 1].night && !this.travelsDetails[index - 1].nightIDF)) {
					result.morning += (item.morning || 0);
				}
			}

			index++;
		}

		N_nightIDF.innerHTML = result.nightIDF.toString();
		N_night.innerHTML = result.night.toString();
		N_morning.innerHTML = result.morning.toString();
		N_meal.innerHTML = result.meal.toString();
		N_mealLittleTravel.innerHTML = result.mealLittleTravel.toString();
		N_km.innerHTML = result.km.toString();
	}
}

export default RecapByUser;
