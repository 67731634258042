export default (playerCount: number) => {
	let given: string[][] = [];

	switch (playerCount) {
		case 3:
		case 4:
			given = [
				['jocker']
			];
			break;
		case 5:
		case 6:
			given = [
				['jocker', 'jocker'],
				['jocker']
			];
			break;
		case 7:
		case 8:
		case 9:
			given = [
				['jocker', 'two'],
				['jocker'],
				['two']
			];
			break;
	}

	return given;
};
