import { toaster } from '@autoprog/core-client';

import SelectEditor from '@libs/agGrid/SelectEditor';
import TextareaEditor from '@libs/agGrid/TextareaEditor';

import { ColDef } from '@ag-grid-enterprise/all-modules';

import M_AddFavorite from '../modals/AddQuote/AddFavorite';
import M_DuplicateMultiple from '../modals/AddQuote/DuplicateMultiple';
import M_SelectProduct from '@modules/Products/js/modals/SelectProduct';

import _ from 'lodash';

import Aggrid from '@libs/utils/Aggrid';
import Decimal from '@libs/utils/Decimal';
import NumericCellEditor from '@libs/agGrid/NumericCellEditor';
import SettingsApps from '@libs/Settings';
import Utils from '@libs/utils/Utils';

import Clipboard from './Clipboard';
import UtilsQuote from './UtilsQuotes';

import S_Products from '@services/Product/ProductService';
import S_Q_Favorite from '@services/Quotes/FavoriteQuoteService';

import ModalManager from '@managers/ModalManager';

import CE_TVA from '@libs/customElement/TVA';

export type SettingQuoteOption = {
	hourlyPriceType: {
		[key: string]: string
	},
	marginType: {
		[key: string]: string
	}
};
class Options {
	public static readonly KILOMETRAGE_COST = 'FRAIS_KM';
	public static readonly DISCOUNT = 'REMISE';
	public static readonly LICENCE = 'LICENCE';
	public static readonly NO_MARGIN = 'AUCUNE_MARGE';
	public static readonly NO_MO = 'AUCUNE_MO';

	private _pref: { [key: string]: any };
	private _infos: { [key: string]: any };
	private _tva: string;
	private _isLock: boolean;

	private colors = [
		'red', 'green',
		'pink', 'light-green',
		'purple', 'lime',
		'deep-purple', 'yellow',
		'indigo', 'amber',
		'blue', 'orange',
		'light-blue', 'deep-orange',
		'cyan', 'brown',
		'teal', 'grey'
	];

	private _quoteOptions: SettingQuoteOption = { hourlyPriceType: {}, marginType: {} };

	public constructor() {
		this._pref = {};
		this._infos = {};
		this._tva = '20';
		this._isLock = false;
	}

	public set isLock(value: boolean) {
		this._isLock = value;
	}

	public updatePref(pref: { [key: string]: any }) {
		this._pref = pref;
	}

	public get pref() {
		return this._pref;
	}

	public updateInfos(infos: { [key: string]: any }) {
		this._infos = infos;
	}

	public get infos() {
		this._infos.state = this._infos.state.toString();
		return this._infos;
	}

	public updateTVA(tva: string) {
		this._tva = tva;
	}

	public get tva() {
		return this._tva.toString();
	}

	public updateQuoteOptions(quoteOptions: SettingQuoteOption) {
		this._quoteOptions = quoteOptions;
	}

	public get quoteOptions() {
		return this._quoteOptions;
	}

	public get comptaVente() {
		const data = (SettingsApps.getInstance().get('COMPTA') || {}) as { [key: string]: any };
		data.comptes = data.comptes || [];

		const res = [];

		for (const item of data.comptes) {
			if (item.isMAT) {
				res.push({
					id: item.number,
					text: `${item.number} : ${item.label}`
				});
			}
		}

		return res;
	}

	private get marginType() {
		return this.objectToArray(this._quoteOptions.marginType || {});
	}

	private get hourlyPriceType() {
		return this.objectToArray(this._quoteOptions.hourlyPriceType || {});
	}

	public getType(params: any) {
		return params.data.type || this._pref.type || Options.NO_MARGIN;
	}

	public getTypeMat(params: any) {
		return params.data.typeMat || this._pref.typeMat || Options.NO_MO;
	}

	public gridMaster(el: HTMLElement, mode: string) {
		const columnDefs: ColDef[] = [
			{
				headerName: '',
				field: '_idGrid',
				cellRenderer: 'agGroupCellRenderer',
				editable: false,
				resizable: false,
				hide: mode === 'modal',
				rowDrag: mode !== 'favorite',
				suppressSizeToFit: true,
				cellClass: 'text-black',
				width: 70,
				valueGetter: () => {
					return '';
				}
			}, {
				headerName: '',
				field: 'notFinish',
				editable: false,
				resizable: false,
				width: 10,
				suppressSizeToFit: true,
				hide: mode === 'modal' || mode === 'favorite',
				cellRenderer: (params) => {
					if (params.value) {
						return `
                            <div class="h-100 justify-content-center align-items-center d-flex">
                                <i tooltip="À finir" class="icon icon-solid-flag"></i>
                            </div>
                        `;
					}

					return '';
				},
				cellClass: 'p-0',
				cellStyle: (params) => {
					const category = params.data.category || '';

					let backgroundColor = '';
					const data: Set<string> = new Set();
					params.api.forEachNode((node: any) => {
						data.add(node.data.category || '');
					});

					let i = 0;
					for (const item of data.values()) {
						if (item === category) {
							backgroundColor = this.colors[i % this.colors.length];
							break;
						}
						i++;
					}

					return {
						backgroundColor: `var(--ap-${backgroundColor}-300)`,
						color: `var(--ap-${backgroundColor}-300-yiq)`
					};
				}
			}, {
				headerName: 'Catégorie',
				field: 'category',
				suppressSizeToFit: true,
				width: 150
			}, {
				headerName: 'Sous-Catégorie',
				field: 'subCategory',
				suppressSizeToFit: true,
				width: 130
			}, {
				headerName: 'Description',
				field: 'name',
				autoHeight: true,
				wrapText: true,
				cellEditor: TextareaEditor,
				cellClass: mode === 'modal' ? 'ag-cell-scrollable' : '',
				cellRenderer: (params) => {
					const value = (params.value || '').split('\n');
					let result = '';

					for (const item of value) {
						result += `<div style="line-height: 17px">${item || '&nbsp;'}</div>`;
					}

					return `<div style="margin-top:10px; margin-bottom:10px;" >${result}</div>`;
				}
			}, {
				headerName: 'Unité',
				field: 'unit',
				suppressSizeToFit: true,
				width: 70
			}, {
				headerName: 'Qté',
				field: 'quantity',
				type: 'numberColumn',
				cellClass: ['text-right', 'text-monospace', 'cursor-pointer'],
				suppressSizeToFit: true,
				width: 70,
				cellEditor: NumericCellEditor,
				cellStyle: (params) => {
					return {
						'background-color': (!params.value || params.value === '0') ? 'var(--ap-red-200)' : ''
					};
				},
				cellRenderer: (params) => {
					return params.value || 0;
				}
			}, {
				headerName: 'P.U.',
				width: 150,
				field: 'price',
				suppressSizeToFit: true,
				editable: false,
				cellClass: 'text-right text-monospace',
				cellRenderer: (params) => {
					let data = 0;

					if (!params.data.disabled) {
						for (const item of params.data.details) {
							if (item.quantity) {
								const tmp = UtilsQuote.calculatePrice(item, this._pref);
								data += tmp.sum;
							}
						}
					}

					data = Utils.roundUp(data, this._pref.decimalNumber);
					return Decimal.setDisplayNumber(data).setSuffixAndHumanizeNumber('€', this._pref.decimalNumber);
				}
			}, {
				headerName: 'TVA',
				field: 'tva',
				width: 80,
				hide: mode === 'favorite',
				cellClass: 'text-right text-monospace',
				suppressSizeToFit: true,
				cellEditor: SelectEditor,
				cellEditorParams: {
					options: {
						data: CE_TVA.getDataToQuote(),
						dropdownParent: $(el),
						minimumResultsForSearch: Infinity
					}
				},
				cellRenderer: (params) => {
					return params.value === '-1' ? 'Non soumis' : params.value || this._tva;
				}
			}, {
				headerName: 'Total HT',
				field: 'sum',
				editable: false,
				width: 150,
				suppressSizeToFit: true,
				cellClass: 'text-right text-monospace',
				cellRenderer: (params) => {
					let data = 0;

					if (!params.data.disabled) {
						for (const item of params.data.details) {
							if (item.quantity) {
								const tmp = UtilsQuote.calculatePrice(item, this._pref);
								data += tmp.sum;
							}
						}

						data *= (params.data.quantity || 0);
					}

					data = Utils.roundUp(data, this._pref.decimalNumber);
					return Decimal.setDisplayNumber(data).setSuffixAndHumanizeNumber('€', this._pref.decimalNumber);
				}
			}, {
				headerName: 'Action',
				editable: false,
				pinned: 'right',
				cellClass: 'p-0',
				hide: mode === 'modal',
				suppressSizeToFit: true,
				width: 110,
				cellRenderer: (params) => {
					const N_favorite = document.createElement('button');

					N_favorite.classList.add('h-100', 'py-0', 'btn-transparent');

					if (params.data._idFav) {
						N_favorite.innerHTML = '<i class="text-dark h5 icon icon-solid-star"></i>';
						N_favorite.setAttribute('confirmation', '');
						N_favorite.title = 'Modifier le favori ?';
					} else {
						N_favorite.innerHTML = '<i class="text-dark h5 icon icon-star"></i>';
					}

					N_favorite.addEventListener('click', async () => {
						if (!params.data._idFav) {
							new M_AddFavorite(params.data).open().then((id) => {
								params.data._idFav = id;

								N_favorite.innerHTML = '<i class="text-dark h5 icon icon-solid-star"></i>';
								N_favorite.setAttribute('confirmation', '');
								N_favorite.title = 'Modifier le favori ?';
							}).catch((e) => {
								console.error(e);
							});
						} else {
							const newData = _.cloneDeep(params.data);
							newData._id = params.data._idFav;

							const data = await S_Q_Favorite.getInstance().save(newData);

							if (!data.err) {
								toaster.success('Sauvegarde réussi');
							}
						}
					});

					const N_div = document.createElement('div');

					N_div.appendChild(N_favorite);

					if (this.infos.state !== '1' && this.infos.state !== '5') {
						const N_delete = document.createElement('button');
						N_delete.classList.add('h-100', 'py-0', 'btn-transparent');
						N_delete.setAttribute('confirmation', '');

						N_delete.innerHTML = '<i class="text-danger h5 icon icon-trash-alt"></i>';

						N_delete.addEventListener('click', () => {
							params.api.applyTransaction({
								remove: [params.data]
							});
						});

						const N_duplicate = document.createElement('button');
						N_duplicate.classList.add('h-100', 'py-0', 'btn-transparent');
						N_duplicate.setAttribute('confirmation', '');

						N_duplicate.innerHTML = '<i class="text-purple h5 icon icon-clone"></i>';

						N_duplicate.addEventListener('click', () => {
							const item = _.cloneDeep(params.data);

							item._idGrid = Utils.generateId();

							params.api.applyTransaction({
								add: [item]
							});
						});

						N_div.appendChild(N_duplicate);
						N_div.appendChild(N_delete);
					}

					return N_div;
				}
			}
		];

		return {
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			//stopEditingWhenCellsLoseFocus: true, //ne marche pas avec les select2
			suppressScrollOnNewData: true,
			columnDefs,
			rowHeight: 250, //il faut une valeur different de 25 (par defaut pour pas que le scroll ce reset)
			defaultColDef: {
				editable: () => {
					return !this._isLock;
				},
				cellStyle: (params: any) => {
					const colId = params.column.getColId();

					params.data.style = params.data.style || {};

					if (params.data.style[colId]) {
						return params.data.style[colId];
					} else {
						return {};
					}
				},
				resizable: true,
				suppressMovable: true,
				suppressMenu: true
			}
		};
	}

	public gridDetails(element: HTMLElement, mode: string, updateGrid?: any) {
		const clipboard = Clipboard.getInstance();

		const settings = (SettingsApps.getInstance().get('COMPTA') || {}) as { [key: string]: boolean };
		const columnDefs: ColDef[] = [
			{
				headerName: '#',
				rowDrag: true,
				field: 'drag',
				pinned: 'left',
				resizable: false,
				width: 50,
				hide: mode === 'favorite',
				suppressSizeToFit: true,
				valueGetter: () => {
					return '';
				}
			},
			{
				headerName: S_Products.getInstance().columnNameReference,
				field: 'reference',
				width: 170,
				cellEditor: SelectEditor,
				cellEditorParams: {
					options: {
						table: 'products',
						isAsync: true,
						rootElement: element
					}
				},
				cellRenderer: (params) => {
					return S_Products.getInstance().cellRendererReference(params.value);
				}
			}, {
				headerName: 'Marque',
				field: 'brand',
				width: 120
			}, {
				headerName: 'Libellé',
				field: 'label',
				suppressSizeToFit: false
			}, {
				headerName: 'Compte comptable',
				field: 'comptaVente',
				width: 120,
				hide: settings.disable,
				editable: true,
				cellEditor: SelectEditor,
				cellEditorParams: {
					options: {
						data: this.comptaVente,
						dropdownParent: $(element),
						minimumResultsForSearch: Infinity
					}
				}
			}, {
				headerName: 'U',
				field: 'unit',
				width: 60,
				valueGetter: (params) => {
					return params.data.unit || 'U';
				}
			}, {
				headerName: 'Qté',
				field: 'quantity',
				width: 80,
				cellClass: 'text-right text-monospace',
				cellRenderer: (params) => {
					return Decimal.setDisplayNumber(params.value).humanizeNumber(0);
				}
			}, {
				headerName: 'Type Mat.',
				field: 'typeMat',
				width: 110,
				cellEditor: SelectEditor,
				cellEditorParams: {
					options: {
						data: this.marginType,
						dropdownParent: $(element),
						minimumResultsForSearch: Infinity
					}
				},
				cellStyle: (params) => {
					if (params.data.notApplyCoefOnMat && params.data.typeMat !== Options.DISCOUNT) {
						return {
							'background-color': 'var(--ap-red-50)',
							color: 'var(--ap-red-900)'
						};
					} else {
						return {
							'background-color': '',
							color: ''
						};
					}
				},
				valueGetter: (params) => {
					return this.getTypeMat(params);
				},
				cellRenderer: (params) => {
					const tmp = this._quoteOptions.marginType;
					return tmp[params.value] || params.value;
				}
			}, {
				headerName: 'P.U. Mat.',
				field: 'price',
				width: 110,
				cellClass: 'text-right p-0 text-monospace',
				valueGetter: (params) => {
					return params.data.price || '0';
				},
				cellRenderer: (params) => {
					const N_div = document.createElement('div');

					N_div.classList.add('px-2');

					N_div.innerHTML = Decimal.setDisplayNumber(params.value).setSuffixAndHumanizeNumber('€', -1);

					if (params.data.reference) {
						if (parseFloat(params.data.costPrice) > parseFloat(params.data.price)) {
							N_div.title = `Prix inférieur au prix d'achat ( ${Decimal.setDisplayNumber(params.data.costPrice).humanizeNumber()} € )`;

							N_div.style.backgroundColor = '#ff8282';
						}
					}

					return N_div;
				}
			}, {
				headerName: 'Type MO',
				field: 'type',
				width: 100,
				cellEditor: SelectEditor,
				cellEditorParams: {
					options: {
						data: this.hourlyPriceType,
						dropdownParent: $(element),
						minimumResultsForSearch: Infinity
					}
				},
				valueGetter: (params) => {
					return this.getType(params);
				},
				cellRenderer: (params) => {
					const tmp = this._quoteOptions.hourlyPriceType;
					return tmp[params.value] || params.value;
				},
				cellStyle: (params) => {
					if (params.data.notApplyCoefOnMO) {
						return {
							'background-color': 'var(--ap-red-50)',
							color: 'var(--ap-red-900)'
						};
					} else {
						return { 'background-color': '', color: '' };
					}
				}
			}, {
				headerName: 'Tps MO',
				field: 'time',
				width: 90,
				cellClass: 'text-right text-monospace',
				valueGetter: (params) => {
					return params.data.time || '0';
				},
				valueSetter: (params) => {
					const value = params.newValue.replace(/\s/gmi, '');

					const number = parseFloat(value);

					const key: string = _.last(value) || '';

					const authorizeKeys: { [key: string]: number } = {
						m: (30 - 4 * 2) * 7,
						s: 5 * 7,
						w: 5 * 7,
						j: 7
					};

					if (authorizeKeys[key]) {
						params.data.time = number * authorizeKeys[key];
					} else {
						params.data.time = params.newValue;
					}

					return true;
				},
				cellRenderer: (params) => {
					const suffix = this.getType(params) === Options.KILOMETRAGE_COST ? 'km' : 'h&nbsp;';
					return Decimal.setDisplayNumber(params.value).setSuffixAndHumanizeNumber(suffix, -1);
				}
			}, {
				headerName: 'Total Mat.',
				field: 'MAT_Total',
				width: 110,
				editable: false,
				cellClass: 'text-right text-monospace',
				cellRenderer: (params) => {
					const data = UtilsQuote.calculatePrice(params.data, this._pref).sumMat;
					return Decimal.setDisplayNumber(data).setSuffixAndHumanizeNumber('€', -1);
				}
			}, {
				headerName: 'Total MO',
				field: 'MO_Total',
				width: 120,
				editable: false,
				cellClass: 'text-right text-monospace',
				cellRenderer: (params) => {
					const data = UtilsQuote.calculatePrice(params.data, this._pref).sumMO;
					return Decimal.setDisplayNumber(data).setSuffixAndHumanizeNumber('€', -1);
				}
			}, {
				headerName: 'PU Total',
				field: 'PU_Total',
				width: 120,
				editable: false,
				hide: !this._pref.displayPUDetails,
				cellClass: 'text-right text-monospace',
				cellRenderer: (params) => {
					const data = UtilsQuote.calculatePrice(params.data, this._pref).price;
					return Decimal.setDisplayNumber(data).setSuffixAndHumanizeNumber('€', -1);
				}
			}, {
				headerName: 'Total',
				field: 'total',
				width: 120,
				editable: false,
				cellClass: 'text-right text-monospace',
				cellRenderer: (params) => {
					const data = UtilsQuote.calculatePrice(params.data, this._pref).sum;
					return Decimal.setDisplayNumber(data).setSuffixAndHumanizeNumber('€', -1);
				}
			}, {
				headerName: 'Action',
				field: 'button',
				width: 110,
				pinned: 'right',
				resizable: false,
				editable: false,
				cellClass: 'p-0',
				cellRenderer: (params) => {
					const N_div = document.createElement('div');

					const N_displayDetails = document.createElement('button');
					N_displayDetails.classList.add('h-100', 'py-0', 'btn-transparent');

					const updateDescriptionButton = (reference: string, value: boolean) => {
						if (reference) {
							if (value) {
								N_displayDetails.setAttribute('tooltip', 'Masquer la description');
								N_displayDetails.classList.add('text-green');
								N_displayDetails.classList.remove('text-red-300');
							} else {
								N_displayDetails.setAttribute('tooltip', 'Afficher la description');
								N_displayDetails.classList.add('text-red-300');
								N_displayDetails.classList.remove('text-green');
							}
						} else {
							N_displayDetails.disabled = true;
							N_displayDetails.classList.add('text-grey-300');
							N_displayDetails.classList.add('cursor-not-allowed');
						}
					};

					updateDescriptionButton(params.data.reference, params.data.displayDetails);

					N_displayDetails.innerHTML = '<i class="h5 icon icon-list-alt"></i>';

					N_displayDetails.addEventListener('click', () => {
						params.data.displayDetails = !params.data.displayDetails;

						updateDescriptionButton(params.data.reference, params.data.displayDetails);

						params.api.applyTransaction({ update: [params.data] });

						updateGrid && updateGrid();
					});

					const N_disabledPrint = document.createElement('button');
					N_disabledPrint.classList.add('h-100', 'py-0', 'btn-transparent', 'text-red-300');

					const updatePrintButton = (value: boolean) => {
						if (value) {
							N_disabledPrint.setAttribute('tooltip', 'Afficher l\'impression');
							N_disabledPrint.classList.remove('text-green');
							N_disabledPrint.classList.add('text-red-300');
						} else {
							N_disabledPrint.setAttribute('tooltip', 'Masquer l\'impression');
							N_disabledPrint.classList.add('text-green');
							N_disabledPrint.classList.remove('text-red-300');
						}
					};

					updatePrintButton(params.data.disabledPrint);

					N_disabledPrint.innerHTML = '<i class="h5 icon icon-printer-outiline "></i>';

					N_disabledPrint.addEventListener('click', () => {
						params.data.disabledPrint = !params.data.disabledPrint;

						updatePrintButton(params.data.disabledPrint);

						params.api.applyTransaction({ update: [params.data] });

						updateGrid && updateGrid();
					});

					const N_delete = document.createElement('button');
					N_delete.classList.add('h-100', 'py-0', 'btn-transparent');
					N_delete.setAttribute('confirmation', '');

					N_delete.innerHTML = '<i class="text-danger h5 icon icon-trash-alt"></i>';

					N_delete.addEventListener('click', () => {
						params.api.applyTransaction({
							remove: [params.data]
						});

						params.api.setPinnedBottomRowData([{}]);

						updateGrid && updateGrid();
					});

					if (!this._isLock) {
						N_div.appendChild(N_displayDetails);

						N_div.appendChild(N_disabledPrint);
						N_div.appendChild(N_delete);
					}

					return N_div;
				}
			}
		];

		const onCellEditingStopped = (params: any) => {
			if (['label'].includes(params.column.getColId())) {
				params.node.setDataValue(params.column.getColId(), params.newValue.trim());
			}

			if (params.column.getColId() === 'reference' && params.oldValue !== params.newValue) {
				S_Products.getInstance().getById(params.data.reference).then((data) => {
					params.node.setData({
						...params.data,
						label: data.name,
						price: data.price,
						unit: data.unit || 'U',
						time: data.time,
						brand: data.brand,
						comptaVente: data.comptaVente,
						costPrice: data.defaultProvider?.costPrice || 0,
						displayDetails: false
					});

					params.api.refreshCells({ force: true, columns: ['brand', 'label', 'comptaVente', 'unit', 'quantity', 'typeMat', 'price', 'type', 'time', 'MAT_Total', 'MO_Total', 'PU_Total', 'total', 'button'] });

					params.api.setPinnedBottomRowData([{}]);

					updateGrid && updateGrid();
				});
			}

			if (['brand', 'label', 'unit', 'reference'].indexOf(params.column.getColId()) === -1) {
				params.api.refreshCells({ force: true, columns: ['MAT_Total', 'MO_Total', 'PU_Total', 'total'] });
			}

			if (params.column.getColId() === 'type') {
				params.api.refreshCells({ force: true, columns: ['time'] });
			}

			params.api.setPinnedBottomRowData([{}]);

			updateGrid && updateGrid();
		};

		const getContextMenuItems = (params: any) => {
			if (params.node?.rowPinned) {
				return [];
			} else {
				const addLine = Aggrid.contextMenuAddLines(params, (i: number) => {
					return {
						_idGrid: Utils.generateId() + '_' + i,
						quantity: 1,
						unit: 'U'
					};
				}, this._isLock);

				const coef = {
					name: 'Coef. Total',
					disabled: this._isLock,
					subMenu: [{
						name: 'Desactiver pour MO',
						action: () => {
							params.node.data.notApplyCoefOnMO = !params.node.data.notApplyCoefOnMO;

							params.node.setData(params.node.data);

							updateGrid && updateGrid();

							params.api.setPinnedBottomRowData([{}]);

							params.api && params.api.refreshCells({ force: true });
						}
					}, {
						name: 'Desactiver pour Mat.',
						action: () => {
							params.node.data.notApplyCoefOnMat = !params.node.data.notApplyCoefOnMat;

							params.node.setData(params.node.data);

							updateGrid && updateGrid();

							params.api.setPinnedBottomRowData([{}]);

							params.api && params.api.refreshCells({ force: true });
						}
					}]
				};

				const duplicate = {
					name: 'Dupliquer',
					disabled: this._isLock,
					icon: '<i class="icon icon-clone"></i>',
					subMenu: [{
						name: 'Ici',
						action: () => {
							const item = _.cloneDeep(params.node.data);

							item._idGrid = Utils.generateId();

							params.api?.applyTransaction({
								add: [item],
								addIndex: params.node.rowIndex
							});

							params.api?.refreshCells({ force: true });

							params.api?.ensureIndexVisible(params.node.rowIndex);

							updateGrid && updateGrid();

							params.api?.setPinnedBottomRowData([{}]);
						}
					}, {
						name: 'A la fin',
						action: () => {
							const item = _.cloneDeep(params.node.data);

							item._idGrid = Utils.generateId();

							params.api?.applyTransaction({
								add: [item]
							});

							let index = -1;

							params.api?.forEachNode(() => {
								index++;
							});

							params.api?.refreshCells({ force: true });
							params.api?.ensureIndexVisible(index);
							params.api?.setPinnedBottomRowData([{}]);

							updateGrid && updateGrid();
						}
					}, {
						name: 'Multiple',
						action: () => {
							const data: { [key: string]: any }[] = [];

							params.api.forEachNode((node: any) => {
								data.push(node.data);
							});

							new M_DuplicateMultiple(data).open().then(({ data, end }) => {
								if (end) {
									params.api.applyTransaction({
										add: data
									});
								} else {
									params.api.applyTransaction({
										add: data,
										addIndex: params.node.rowIndex
									});
								}

								if (end) {
									let index = -1;

									params.api.forEachNode(() => {
										index++;
									});

									params.api.ensureIndexVisible(index);
								}

								params.api?.refreshCells({ force: true });
								params.api?.setPinnedBottomRowData([{}]);

								updateGrid && updateGrid();
							});
						}
					}]
				};

				const products = {
					name: 'Produits',
					disabled: this._isLock,
					icon: '<i class="icon icon-solid-box-open"></i>',
					subMenu: [{
						name: 'Actualiser',
						icon: '<i class="icon icon-solid-redo"></i>',
						subMenu: [{
							name: 'Prix',
							subMenu: [{
								name: 'Ligne',
								disabled: !(params.node && params.node.data.reference),
								action: async () => {
									const data = await S_Products.getInstance().getById(params.node.data.reference);

									params.node.data.price = data.price;
									params.node.data.costPrice = data.defaultProvider?.costPrice || 0;

									params.node.setData(params.node.data);

									params.api.refreshCells({ force: true, node: [params.node] });
									params.api.setPinnedBottomRowData([{}]);

									updateGrid && updateGrid();
								}
							}, {
								name: 'Groupe',
								action: async () => {
									if (params.api) {
										const nodes: any[] = [];

										params.api.forEachNode((node: any) => {
											nodes.push(node);
										});

										for (const node of nodes) {
											if (node.data.reference) {
												const data = await S_Products.getInstance().getById(node.data.reference);

												node.data.price = data.price;
												node.data.costPrice = data.defaultProvider?.costPrice || 0;

												node.setData(node.data);
											}
										}

										params.api.refreshCells({ force: true });
										params.api.setPinnedBottomRowData([{}]);

										updateGrid && updateGrid();
									}
								}
							}]
						}, {
							name: 'Libellé',
							subMenu: [{
								name: 'Ligne',
								disabled: !(params.node && params.node.data.reference),
								action: async () => {
									const data = await S_Products.getInstance().getById(params.node.data.reference);

									params.node.data.label = data.name;

									params.node.setDataValue('label', data.name);

									params.api.refreshCells({ force: true, node: [params.node] });

									updateGrid && updateGrid();
								}
							}, {
								name: 'Groupe',
								action: async () => {
									const nodes: any[] = [];

									params.api.forEachNode((node: any) => {
										nodes.push(node);
									});

									for (const node of nodes) {
										const data = await S_Products.getInstance().getById(node.data.reference);

										node.data.label = data.name;

										node.setDataValue('label', data.name);
									}

									params.api.refreshCells({ force: true });

									updateGrid && updateGrid();
								}
							}]
						}]

					}, {
						name: 'Sélectionner',
						icon: '<i class="icon icon-solid-list"></i>',
						action: () => {
							new M_SelectProduct().open().then(({ products, groups }) => {
								const data = [];

								for (const id in products) {
									const product = products[id].product;
									const quantity = products[id].quantity;

									data.push({
										_idGrid: Utils.generateId(),
										quantity,
										reference: product._id,
										brand: product.brand,
										price: product.price.value,
										costPrice: product.defaultProvider?.costPrice?.value || 0,
										displayDetails: false,
										label: product.name,
										time: product.time,
										comptaVente: product.comptaVente,
										unit: product.unit || 'U'
									});
								}

								for (const key in groups) {
									data.push({
										_idGrid: Utils.generateId(),
										label: groups[key][0].name,
										quantity: 1
									});

									for (const item of groups[key]) {
										const product = item.product;
										const quantity = item.quantity;

										data.push({
											_idGrid: Utils.generateId(),
											quantity,
											reference: product._id,
											brand: product.brand,
											price: product.price.value,
											costPrice: product.defaultProvider?.costPrice?.value || 0,
											displayDetails: false,
											label: product.name,
											time: product.time,
											comptaVente: product.comptaVente,
											unit: product.unit || 'U',
											disabledPrint: true
										});
									}
								}

								params.api.applyTransaction({
									add: data
								});

								params.api.setPinnedBottomRowData([{}]);
								params.api.refreshCells({ force: true });

								updateGrid && updateGrid();
							}).catch(() => { });
						}
					}, {
						name: 'Créer',
						icon: '<i class="icon icon-solid-plus"></i>',
						action: async () => {
							const data = await ModalManager.getInstance().openWithReturnData('products');

							params.api.applyTransaction({
								add: [{
									_idGrid: Utils.generateId(),
									quantity: 1,
									reference: data._id,
									brand: data.brand,
									price: data.price || data.buyingPrice,
									costPrice: data.defaultProvider?.costPrice || 0,
									displayDetails: false,
									label: data.name,
									time: data.time,
									comptaVente: data.comptaVente,
									unit: data.unit || 'U'
								}]
							});

							let index = -1;

							params.api.forEachNode(() => {
								index++;
							});

							params.api.ensureIndexVisible(index);
							params.api.refreshCells({ force: true });
							params.api.setPinnedBottomRowData([{}]);

							updateGrid && updateGrid();
						}
					}, {
						name: 'Dupliquer',
						icon: '<i class="icon icon-clone"></i>',
						disabled: true || !params.node?.data.reference,
						action: async () => {
							const data = await ModalManager.getInstance().openDuplicateWithReturnData('products', params.node.data.reference);

							params.api.applyTransaction({
								add: [{
									_idGrid: Utils.generateId(),
									quantity: 1,
									reference: data._id,
									brand: data.brand,
									price: data.price || data.buyingPrice,
									costPrice: data.defaultProvider?.costPrice || 0,
									displayDetails: false,
									label: data.name,
									time: data.time,
									comptaVente: data.comptaVente,
									unit: data.unit || 'U'
								}]
							});

							let index = -1;

							params.api.forEachNode(() => {
								index++;
							});

							params.api.ensureIndexVisible(index);
							params.api.refreshCells({ force: true });
							params.api.setPinnedBottomRowData([{}]);

							updateGrid && updateGrid();
						}
					}, {
						name: 'Éditer',
						icon: '<i class="icon icon-edit"></i>',
						disabled: !params.node?.data.reference,
						action: async () => {
							const data = await ModalManager.getInstance().openWithReturnData('products', params.node.data.reference);

							params.node.data.price = data.price;
							params.node.data.brand = data.brand;
							params.node.data.comptaVente = data.comptaVente;
							params.node.data.costPrice = data.defaultProvider?.costPrice || 0;

							params.node.setData(params.node.data);

							params.api.refreshCells({ force: true, node: [params.node] });
							params.api.setPinnedBottomRowData([{}]);

							updateGrid && updateGrid();
						}
					}]
				};

				const copy = {
					name: 'Copier',
					disabled: !params.node,
					icon: '<i class="icon icon-copy"></i>',
					action: () => {
						clipboard.setData(_.cloneDeep(params.node.data), 'details');
					}
				};

				const paste = {
					name: 'Coller',
					disabled: this._isLock || !clipboard.getData('details'),
					icon: '<i class="icon icon-solid-paste"></i>',
					action: () => {
						const data = clipboard.getData('details');

						params.api.applyTransaction({
							add: [data]
						});

						params.api.refreshCells({ force: true });
						params.api.setPinnedBottomRowData([{}]);

						updateGrid && updateGrid();
					}
				};

				const copyReference = {
					name: 'Copier référence',
					disabled: !(params.node && params.node.data.reference),
					icon: '<i class="icon icon-copy"></i>',
					action: async () => {
						const data = await S_Products.getInstance().getById(params.node.data.reference);

						try {
							navigator.clipboard.writeText(data.reference);
							toaster.success('Copie réussie');
						} catch (err) { //En cas d'erreur
							toaster.error('Erreur lors de la copie');
						}
					}
				};

				const style = this.styleContextMenu(params, () => {
					updateGrid && updateGrid();
				});

				if (params.node) {
					if (params.node.data.notApplyCoefOnMO) {
						coef.subMenu[0].name = 'Activer pour MO';
					}

					if (params.node.data.notApplyCoefOnMat) {
						coef.subMenu[1].name = 'Activer pour Mat.';
					}

					if (params.node.data.reference) {
						return [
							...addLine,
							'separator',
							products,
							'separator',
							coef,
							duplicate,
							style,
							'separator',
							copy,
							paste,
							copyReference
						];
					} else {
						return [
							...addLine,
							'separator',
							products,
							'separator',
							coef,
							duplicate,
							style,
							'separator',
							copy,
							paste,
							copyReference
						];
					}
				} else {
					return [
						...addLine,
						'separator',
						products,
						'separator',
						copy,
						paste,
						copyReference
					];
				}
			}
		};

		const getRowStyle = (params: any) => {
			if (params.node.rowPinned) {
				return {
					'font-weight': 'bold',
					'font-size': '15px'
				};
			} else {
				if (params.data.disabledPrint) {
					return {
						'background-color': 'var(--ap-blue-50)',
						color: 'var(--ap-blue-900)'
					};
				}

				return { 'background-color': '', color: '' };
			}
		};

		return {
			columnDefs,
			onCellEditingStopped,
			getRowStyle,
			getContextMenuItems,
			suppressDragLeaveHidesColumns: true,
			//stopEditingWhenCellsLoseFocus: true, //ne marche pas avec les select2
			suppressScrollOnNewData: true,
			animateRows: true,
			rowDragManaged: true,
			defaultColDef: {
				//headerClass: ['px-1', 'text-center'],
				suppressMenu: true,
				suppressSizeToFit: true,
				resizable: true,
				suppressMovable: true,
				cellStyle: (params: any) => {
					const colId = params.column.getColId();

					params.data.style = params.data.style || {};

					if (params.data.style[colId]) {
						return params.data.style[colId];
					} else {
						return {};
					}
				},
				editable: () => {
					return !this._isLock;
				}
			},
			onGridReady: (params: any) => {
				params.api.sizeColumnsToFit();
			},
			onFirstDataRendered: (params: any) => {
				params.api.setPinnedBottomRowData([{}]);
			},
			isFullWidthCell: (params: any) => {
				return !!params.rowPinned;
			},
			fullWidthCellRenderer: (params: any) => {
				const data = UtilsQuote.calculateFooterDetails(params, this._pref);

				const N_div = document.createElement('div');

				N_div.classList.add('h-100', 'font-bold', 'h5', 'row', 'd-flex', 'align-content-center');

				N_div.innerHTML = `
                    <div class="col text-center">
                        Prix Unitaire : 
                        ${data.price.setSuffixAndHumanizeNumber('€', this._pref.decimalNumber)}
                    </div>
                    <div class="col text-center">
                        Tps MO : 
                        ${data.time.setSuffixAndHumanizeNumber('h', -1)}
                    </div>
                    <div class="col text-center">
                        Km : 
                        ${data.km.setSuffixAndHumanizeNumber('km', -1)}
                    </div>
                    <div class="col text-center">
                        Total Matériel :
                        ${data.sumMaterials.setSuffixAndHumanizeNumber('€', this._pref.decimalNumber)}
                    </div>
                    <div class="col text-center">
                        Marges : 
                        ${data.marges.setSuffixAndHumanizeNumber('€', this._pref.decimalNumber)}
                    </div>
                `;

				return N_div;
			},
			pinnedBottomRowData: [{}]
		};
	}

	private objectToArray(object: { [key: string]: any }) {
		const result = [];

		for (const key in object) {
			result.push({
				id: key,
				text: object[key]
			});
		}

		return result;
	}

	public styleContextMenu(params: { [key: string]: any }, update?: any) {
		const style = {
			name: 'Style',
			subMenu: [{
				name: 'Gras',
				icon: '<i class="icon icon icon-solid-bold"></i>',
				action: () => {
					const colId = params.column.getColId();

					params.node.data.style = params.node.data.style || {};
					params.node.data.style[colId] = params.node.data.style[colId] || {};

					if (params.node.data.style[colId]['font-weight']) {
						params.node.data.style[colId]['font-weight'] = '';
					} else {
						params.node.data.style[colId]['font-weight'] = 'bold';
					}

					params.node.setData(params.node.data);

					params.api?.refreshCells({ columns: [colId], force: true });

					update && update();
				}
			}, {
				name: 'Italic',
				icon: '<i class="icon icon-solid-italic"></i>',
				action: () => {
					const colId = params.column.getColId();

					params.node.data.style = params.node.data.style || {};
					params.node.data.style[colId] = params.node.data.style[colId] || {};

					if (params.node.data.style[colId]['font-style']) {
						params.node.data.style[colId]['font-style'] = '';
					} else {
						params.node.data.style[colId]['font-style'] = 'italic';
					}

					params.node.setData(params.node.data);

					params.api?.refreshCells({ columns: [colId], force: true });

					update && update();
				}
			}, {
				name: 'Barré',
				icon: '<i class="icon icon-solid-strikethrough"></i>',
				action: () => {
					const colId = params.column.getColId();

					params.node.data.style = params.node.data.style || {};
					params.node.data.style[colId] = params.node.data.style[colId] || {};

					let value = params.node.data.style[colId]['text-decoration'] || '';

					if (value.indexOf('line-through') !== -1) {
						value = value.replace('line-through', '');
					} else {
						value += ' line-through';
					}

					params.node.data.style[colId]['text-decoration'] = value.trim();

					params.node.setData(params.node.data);

					params.api?.refreshCells({ columns: [colId], force: true });

					update && update();
				}
			}, {
				name: 'Souligné',
				icon: '<i class="icon icon-solid-underline"></i>',
				action: () => {
					const colId = params.column.getColId();

					params.node.data.style = params.node.data.style || {};
					params.node.data.style[colId] = params.node.data.style[colId] || {};

					let value = params.node.data.style[colId]['text-decoration'] || '';

					if (value.indexOf('underline') !== -1) {
						value = value.replace('underline', '');
					} else {
						value += ' underline';
					}

					params.node.data.style[colId]['text-decoration'] = value.trim();

					params.node.setData(params.node.data);

					params.api?.refreshCells({ columns: [colId], force: true });

					update && update();
				}
			}]
		};

		return style;
	}
}

export default Options;
