export default (playerCount: number) => {
	let topPlayerCount = 0;

	switch (playerCount) {
		case 3:
		case 4:
			topPlayerCount = 1;
			break;
		case 5:
		case 6:
			topPlayerCount = 2;
			break;
		case 7:
		case 8:
		case 9:
			topPlayerCount = 3;
			break;
	}

	return topPlayerCount;
};
