import { global } from '@autoprog/core-client';

import _ from 'lodash';
import axios from 'axios';

export type Player = {

	_id: string,
	_rev?: string,
	firstname: string,
	lastname: string

};

class PlayersService {
	private fakeUsers: Player[] = [];

	public constructor() {
		this.fakeUsers = [
			{
				_id: 'stge',
				firstname: 'Stagiaire',
				lastname: '1'
			}
		];
	}

	public async getAll(): Promise<Player[]> {
		const users = (await axios.get(`${global.COUCHDB_URL}/${global.COUCHDB_PREFIX}users/_design/all/_view/byID`, {
			params: {
				include_docs: true
			}
		})).data;

		return [...this.fakeUsers, ..._.map(users.rows, 'doc')];
	}

	public async getAllSorted(): Promise<Player[]> {
		const players = await this.getAll();
		const finalPlayers: Player[] = [];

		let rows = (await axios.get(`${global.COUCHDB_URL}/autoprog_games/_design/all/_view/players`, {
			params: {
				group_level: 1
			}
		})).data.rows;

		if (rows) {
			rows = _.sortBy(rows, 'value').reverse();
			for (const row of rows) {
				const index = _.findIndex(players, { _id: row.key });
				finalPlayers.push(...players.splice(index, 1));
			}
			finalPlayers.push(...players);
		}

		return finalPlayers;
	}

	public async getByID(playerID: string): Promise<any> {
		const fu = _.find(this.fakeUsers, playerID);
		if (fu) {
			return fu;
		}

		const res = await axios.get(`${global.COUCHDB_URL}/${global.COUCHDB_PREFIX}users/${playerID}`);
		return res.data;
	}

	public async getByIDs(playersID: string[]): Promise<Player[]> {
		const promises = [];

		for (const playerID of playersID) {
			promises.push(this.getByID(playerID));
		}

		return await Promise.all(promises);
	}
}

export default PlayersService;
