import { Controller, Tabs } from '@autoprog/core-client';

import C_Color from '../tabs/Color';
import C_Icons from '../tabs/Icons';

import T_Color from '../../tpl/tabs/color.html';
import T_Icons from '../../tpl/tabs/icons.html';

import '../../css/theme.scss';

class ThemeCtrl extends Controller {
	constructor(el: HTMLElement) {
		super(el);

		const tabs = new Tabs({
			navElement: el.querySelector('.tabs-container') as HTMLElement,
			renderElement: el.querySelector('#page-content') as HTMLElement
		});

		tabs.add({
			name: 'Icones',
			controller: C_Icons,
			template: T_Icons,
			default: true
		});

		tabs.add({
			name: 'Couleur',
			controller: C_Color,
			template: T_Color
		});
	}

	public destructor() {

	}
}

export default ThemeCtrl;
