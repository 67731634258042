import { Module, Modules, Router, global } from '@autoprog/core-client';

import moment from 'moment';

import Navigation from '@libs/Navigation';
import NavigationMobile from '@libs/NavigationMobile';
import Notifications from '@modules/Apps/js/libs/Notifications';

import C_Calendar from './js/controllers/Calendar';
import C_Holidays from './js/controllers/Holidays';
import C_Hours from './js/controllers/Hours';
import C_HoursAll from './js/controllers/HoursAll';
import C_HoursMobile from './js/controllers/Hours.mobile';
import C_Travels from './js/controllers/Travels';

import T_database from '@tpl/database.html';

import T_Calendar from './tpl/Calendar.html';
import T_Hours from './tpl/Hours.html';
import T_HoursAll from './tpl/HoursAll.html';
import T_HoursMobile from './tpl/Hours.mobile.html';

import M_validateHolidays from '@modules/Hours/js/modals/ValidateHolidays';

import './css/calendar.scss';
import SettingsNotificationTabs from '@modules/Apps/js/tabs/Settings.Notification';

class AppsModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);

		moment.updateLocale('fr', {
			//@ts-ignore
			week: {
				dow: 1
			}
		} as any);
	}

	public async preInit() {
		const navigation = Navigation.getInstance();
		navigation.addCategory({
			name: 'Horaires',
			order: 100
		});
		navigation.addLink({
			order: 0,
			category: 'Horaires',
			name: 'Heures',
			type: 'module',
			module: 'hours/hours',
			permissions: ['HOURS'],
			icon: 'file-user'
		});
		navigation.addLink({
			order: 1,
			category: 'Horaires',
			name: 'Absences',
			type: 'module',
			permissions: ['HOURS._HOLIDAYS'],
			module: 'hours/holidays',
			icon: 'earth'
		});
		navigation.addLink({
			order: 2,
			category: 'Horaires',
			name: 'Déplacements',
			type: 'module',
			module: 'hours/travels',
			permissions: ['HOURS._TRAVELS'],
			icon: 'car'
		});
		navigation.addLink({
			order: 4,
			category: 'Horaires',
			name: 'Calendrier',
			type: 'module',
			module: 'hours/calendar',
			permissions: ['HOURS._CALENDAR'],
			icon: 'calendar-2'
		});
		navigation.addLink({
			order: 5,
			category: 'Horaires',
			name: 'Recap Heures',
			type: 'module',
			module: 'hours/hoursAll',
			permissions: ['HOURS._RECAP'],
			icon: 'time'
		});

		NavigationMobile.getInstance().addLink({
			icon: 'icon-solid-history icon-flip-horizontal',
			name: 'Heures',
			order: 7,
			dataset: {
				link: 'hours'
			}
		});
		SettingsNotificationTabs.addInput({
			text: 'Vacances / Récup',
			select: [
				{
					text: 'Validation',
					name: 'holidays.validate'
				}, {
					text: 'Informations validation',
					name: 'holidays.infos.validate'
				}, {
					text: 'Informations annulation',
					name: 'holidays.infos.cancel'
				}
			]
		}, 2);

		SettingsNotificationTabs.addInput({
			text: 'Arret Maladie',
			select: [
				{
					text: 'Informations',
					name: 'holidays.infos.sick'
				}
			]
		}, 2);

		Notifications.addCategory(['holiday'], {
			icon: 'icon-solid-user-slash',
			textFilter: 'Absence',
			isFilter: true,
			action: async (item: { [key: string]: any }, N_div: HTMLElement) => {
				if (item.data && item.data.id) {
					new M_validateHolidays(item.data.id).open().then(async () => {
						await Notifications.closeNotification(item._id);
						N_div.remove();
					});
				}
			}
		});
	}

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: '/module/hours/travels',
			controller: C_Travels as any,
			tpl: T_database,
			requireValidUser: true
		});

		if (global.IS_MOBILE) {
			router.addRoute({
				url: '/module/hours/hours',
				controller: C_HoursMobile as any,
				tpl: T_HoursMobile,
				requireValidUser: true
			});

			router.addRoute({
				url: '/module/hours/hours/:tabs',
				controller: C_HoursMobile as any,
				tpl: T_HoursMobile,
				requireValidUser: true
			});
		} else {
			router.addRoute({
				url: '/module/hours/hours',
				controller: C_Hours as any,
				tpl: T_Hours,
				requireValidUser: true
			});
		}

		router.addRoute({
			url: '/module/hours/holidays',
			controller: C_Holidays as any,
			tpl: T_database,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/hours/hoursAll',
			controller: C_HoursAll as any,
			tpl: T_HoursAll,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/hours/hoursAll/:tabs',
			controller: C_HoursAll as any,
			tpl: T_HoursAll,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/hours/calendar',
			controller: C_Calendar as any,
			tpl: T_Calendar,
			requireValidUser: true
		});
	}

	public async postInit() {

	}
}

Modules.registerModule('Heures', AppsModule);
