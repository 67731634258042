import { Modal, global } from '@autoprog/core-client';

import _ from 'lodash';
import axios from 'axios';

import T_modal from '../../tpl/modals/EditIcon.html';

import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

class EditIcon extends Modal {
	constructor(icon: string) {
		super({
			tpl: T_modal
		});

		this.on('opened', async () => {
			const N_ModalTitle = this.element.querySelector('.modal-title') as HTMLElement;

			N_ModalTitle.innerHTML = `
                icon-${icon}
                <i class="icon icon-${icon}"></i>
            `;

			let oldData = {
				tags: [] as any[]
			};

			try {
				oldData = (await axios.get(`${global.COUCHDB_URL}/autoprog_icons/${icon}`)).data;
			} catch (e) {

			}

			oldData.tags = _.map(oldData.tags, (item) => {
				return { tag: item };
			});

			const gridOptions: GridOptions = {
				rowData: oldData.tags,
				columnDefs: [{
					editable: true,
					headerName: 'Tags',
					field: 'tag'
				}, {
					headerName: '#',
					width: 80,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						const N_delete = document.createElement('button');

						N_delete.classList.add('h-100', 'py-0', 'btn-transparent');
						N_delete.setAttribute('confirmation', '');

						N_delete.innerHTML = '<i class="text-danger h5 icon icon-trash-alt"></i>';

						N_delete.addEventListener2('click', () => {
							params.api?.updateRowData({
								remove: [params.node.data]
							});
						});

						return N_delete;
					}
				}],
				defaultColDef: {
					suppressMenu: true
				},
				getContextMenuItems: (params) => {
					return [{
						name: 'Ajouter une ligne',
						action: () => {
							params.api?.applyTransaction({
								add: [{}]
							});
						}
					}];
				},
				onGridReady: (params) => {
					params.api?.sizeColumnsToFit();
				}
			};

			const N_grid = this.element.querySelector('#grid') as HTMLElement;

			new Grid(N_grid, gridOptions, { modules: AllModules });

			const N_add = this.element.querySelector('#add') as HTMLButtonElement;

			N_add.addEventListener2('click', () => {
				gridOptions.api?.applyTransaction({
					add: [{}]
				});
			});

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener2('click', async () => {
				let data = {
					_id: icon,
					tags: [] as string[]
				};

				gridOptions.api?.stopEditing();

				gridOptions.api?.forEachNode((node) => {
					data.tags.push(node.data.tag);
				});

				let oldData = {};
				try {
					oldData = (await axios.get(`${global.COUCHDB_URL}/autoprog_icons/${icon}`)).data;
				} catch (e) {

				}

				data = {
					...oldData,
					...data
				};

				await axios.put(`${global.COUCHDB_URL}/autoprog_icons/${icon}`, data);

				this.resolve();
			});
		});
	}
}

export default EditIcon;
