import { Tab, toaster } from '@autoprog/core-client';

import CSV from '@autoprog/csv';

class JSONTab extends Tab {
	constructor(el: HTMLElement) {
		super(el);

		const N_value = el.querySelector('#value') as HTMLTextAreaElement;
		const N_result = el.querySelector('#result') as HTMLTextAreaElement;

		const N_indent = el.querySelector('#indent') as HTMLButtonElement;
		const N_minimize = el.querySelector('#minimize') as HTMLButtonElement;
		const N_toCSV = el.querySelector('#toCSV') as HTMLButtonElement;
		const N_toJSON = el.querySelector('#toJSON') as HTMLButtonElement;

		const N_header = el.querySelector('#header') as HTMLInputElement;
		const N_separator = el.querySelector('#separator') as HTMLInputElement;

		N_indent.addEventListener2('click', () => {
			try {
				N_result.value = JSON.stringify(JSON.parse(N_value.value), null, 4);
			} catch (e) {
				toaster.error('JSON non valide : ' + (e as any).toString().match(/(line )([0-9]{1,})( column )([0-9]{1,}) /gmi));
			}
		});

		N_minimize.addEventListener2('click', () => {
			try {
				N_result.value = JSON.stringify(JSON.parse(N_value.value));
			} catch (e) {
				toaster.error('JSON non valide : ' + (e as any).toString().match(/(line )([0-9]{1,})( column )([0-9]{1,}) /gmi));
			}
		});

		N_toCSV.addEventListener2('click', () => {
			try {
				const tmp = JSON.parse(N_value.value);
				N_result.value = CSV.stringify(tmp, N_header.checked, '\n', N_separator.value === '\\t' ? '\t' : N_separator.value);
			} catch (e) {
				toaster.error('JSON non valide : ' + (e as any).toString().match(/(line )([0-9]{1,})( column )([0-9]{1,}) /gmi));
			}
		});

		N_toJSON.addEventListener2('click', () => {
			const tmp = CSV.parse(N_value.value, N_header.checked, false, false, '\n', N_separator.value === '\\t' ? '\t' : N_separator.value);

			N_result.value = JSON.stringify(tmp, null, 4);
		});
	}

	public destructor() {
	}
}

export default JSONTab;
