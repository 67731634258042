import { Tab } from '@autoprog/core-client';

import color from '../libs/colors';

class ColorTab extends Tab {
	private colors: string[] = [];
	private shades: string[] = [];
	private el: HTMLElement;

	constructor(el: HTMLElement) {
		super(el);

		this.el = el;

		this.init();
	}

	private async init() {
		const res: { [key: string]: any } = color;
		const colorsMap: { [key: string]: any } = {};
		const tmpColors = res['material-colors'] || {};
		this.shades = [];
		this.colors = [];
		let firstTime = true;
		for (const tmpKey in tmpColors) {
			const key = tmpKey.replace(/'/g, '');
			colorsMap[key] = {};
			this.colors.push(key);
			for (const tmpShade in tmpColors[tmpKey]) {
				const shade = tmpShade.replace(/'/g, '');

				if (firstTime) {
					this.shades.push(shade);
					if (shade === '500') {
						this.shades.push('');
					}
				}

				if (shade === '500') {
					colorsMap[key][''] = tmpColors[tmpKey][tmpShade];
				}
				colorsMap[key][shade] = tmpColors[tmpKey][tmpShade];
			}
			firstTime = false;
		}

		//Génération de la table des couleurs d'arriere plan
		this.generateColorsTable(this.el.querySelector('#bg-table') as HTMLTableElement, (N_cell: HTMLElement, shade: string, color: string) => {
			if (shade) {
				N_cell.classList.add(`bg-${color}-${shade}`);
			} else {
				N_cell.classList.add(`bg-${color}`);
			}
		});

		//Génération de la table des dégradés
		const N_gradientTable = document.querySelector('#gradient-table') as HTMLTableElement;
		const gradientTypes = [
			'',
			'0',
			'45',
			'90',
			'135',
			'h',
			'v'
		];
		const N_row = N_gradientTable.insertRow();
		N_row.insertCell().innerHTML = '#';
		for (const color of this.colors) {
			N_row.insertCell().innerHTML = color;
		}
		for (const gType of gradientTypes) {
			const N_row = N_gradientTable.insertRow();
			N_row.insertCell().innerHTML = gType;
			for (const color of this.colors) {
				const N_cell = N_row.insertCell();
				if (gType) {
					N_cell.classList.add(`gradient-${gType}-${color}`);
					N_cell.innerHTML = `gradient-${gType}-${color}`;
				} else {
					N_cell.classList.add(`gradient-${color}`);
					N_cell.innerHTML = `gradient-${color}`;
				}
			}
		}

		//Génération de la table des couleurs de texte
		this.generateColorsTable(this.el.querySelector('#text-table') as HTMLTableElement, (N_cell: HTMLElement, shade: string, color: string) => {
			if (shade) {
				N_cell.classList.add(`text-${color}-${shade}`);
			} else {
				N_cell.classList.add(`text-${color}`);
			}
		});

		//Génération de la table des couleurs des boutons
		this.generateColorsTable(this.el.querySelector('#btn-table') as HTMLTableElement, (N_cell: HTMLElement, shade: string, color: string) => {
			if (shade) {
				N_cell.classList.add(`text-${color}-${shade}`);
			} else {
				N_cell.classList.add(`text-${color}`);
			}
			const N_btn = document.createElement('button');
			N_btn.innerHTML = 'btn';
			if (shade) {
				N_btn.classList.add('btn', 'w-100', `btn-${color}-${shade}`);
			} else {
				N_btn.classList.add('btn', 'w-100', `btn-${color}`);
			}
			N_cell.classList.add('p-0');
			N_cell.append(N_btn);
		});

		//Génération de la table des couleurs des boutons
		this.generateColorsTable(this.el.querySelector('#btn-outline-table') as HTMLTableElement, (N_cell: HTMLElement, shade: string, color: string) => {
			if (shade) {
				N_cell.classList.add(`text-${color}-${shade}`);
			} else {
				N_cell.classList.add(`text-${color}`);
			}
			const N_btn = document.createElement('button');
			N_btn.innerHTML = 'btn';
			if (shade) {
				N_btn.classList.add('btn-outline', 'w-100', `btn-${color}-${shade}`);
			} else {
				N_btn.classList.add('btn-outline', 'w-100', `btn-${color}`);
			}
			N_cell.classList.add('p-0');
			N_cell.append(N_btn);
		});
	}

	private generateColorsTable(N_table: HTMLTableElement, cb: any) {
		const N_row = N_table.insertRow();

		N_row.insertCell().innerHTML = '#';
		for (const color of this.colors) {
			N_row.insertCell().innerHTML = color;
		}
		for (const shade of this.shades) {
			const N_row = N_table.insertRow();
			N_row.insertCell().innerHTML = shade;
			for (const color of this.colors) {
				const N_cell = N_row.insertCell();
				cb(N_cell, shade, color);
				if (N_cell.innerHTML === '') {
					N_cell.innerHTML = N_cell.className;
				}
			}
		}
	}
}

export default ColorTab;
