import GameService, { Round } from '../services/GamesService';
import PlayersService, { Player } from '../services/PlayersService';
import { toaster } from '@autoprog/core-client';

import moment from 'moment';

import _ from 'lodash';

class Game {
	private gameService: GameService;
	private id: string;
	private rev?: string;
	private players: Map<string, Player>;
	private rounds: Round[];

	public constructor(id?: string) {
		this.gameService = new GameService();
		this.id = id || '';
		this.players = new Map();
		this.rounds = [];
	}

	public async init() {
		if (!this.id) {
			this.id = moment().format('YYYY-MM-DD-HH-mm-ss');
		} else {
			const game = await this.gameService.getByID(this.id);
			await this.loadPlayers(game.players);
			this.rounds = game.rounds || [];
			this.rev = game._rev;
		}
	}

	/**
	 * @description Charge les joueurs depuis une liste d'ID
	 * @param playersIDs Tableau d'ID de joueurs
	 */
	private async loadPlayers(playersIDs: string[]) {
		const promises = [];
		const playerService = new PlayersService();
		this.players.clear();
		for (const pID of playersIDs) {
			promises.push(new Promise<void>(async (resolve) => {
				const player = await playerService.getByID(pID);
				this.players.set(player._id, player);
				resolve();
			}));
		}
		return await Promise.all(promises);
	}

	/**
	 * @description Permet de définir la liste des joueurs
	 * @param playersIDs Tableau d'ID de joueurs
	 */
	public async setPlayers(playersIDs: string[]) {
		await this.loadPlayers(playersIDs);
		await this.save();
	}

	public getPlayers() {
		return _.cloneDeep(this.players);
	}

	public getRoundsCount() {
		return _.cloneDeep(this.rounds.length);
	}

	public getRounds(): Round[] {
		return _.cloneDeep(this.rounds);
	}

	public getRound(index: number): Round {
		return _.cloneDeep(this.rounds[index]);
	}

	public getLastRound() {
		return _.cloneDeep(this.rounds[this.rounds.length - 1]);
	}

	public async setRound(index: number, round: Round) {
		this.rounds[index] = round;
		await this.save();
	}

	public async addRound(round: Round) {
		await this.setRound(this.rounds.length, round);
	}

	/**
	 * @description Permet de saugevarder la partie
	 */
	private async save() {
		if (this.rounds.length) {
			try {
				const rev = await this.gameService.update({
					_id: this.id,
					_rev: this.rev,
					players: Array.from(this.players.keys()),
					rounds: this.rounds
				});
				this.rev = rev;

				toaster.success('Partie sauvegardée');
			} catch (e) {
				toaster.error('Partie non sauvegardée');
				console.error(e);
				throw e;
			}
		}
	}
}

export default Game;
