import { Modal } from '@autoprog/core-client';

import _ from 'lodash';
import h from 'hyperscript';

import T_EditGame from '../../tpl/modals/editGame.html';

import Game from '../libs/Game';

import AddEditRound from './AddEditRound';
import PreviousRoundRecap from './PreviousRoundRecap';
import SelectPlayers from './SelectPlayers';
import ShameLooser from './ShameLooser';

import getTopPlayerCount from '../libs/getTopPlayerCount';

class EditGame extends Modal {
	private game: Game;

	public constructor(game: Game) {
		super({
			tpl: T_EditGame,
			backdrop: 'static'
		});

		this.game = game;

		this.on('opened', async () => {
			if (this.game.getPlayers().size === 0) {
				await new SelectPlayers(this.game).open();
			}

			const N_addRoundBtn = this.element.querySelector('#add-round-btn') as HTMLButtonElement;
			N_addRoundBtn.addEventListener2('click', () => {
				//Si deja un round est joué
				if (this.game.getRoundsCount() > 0) {
					(new PreviousRoundRecap(this.game)).open().then(() => {
						(new AddEditRound(this.game)).open().then(() => {
							this.update();
						});
					});
				} else {
					(new AddEditRound(this.game)).open().then(() => {
						this.update();
					});
				}
			});

			const N_selectPlayersBtn = this.element.querySelector('#select-players-btn') as HTMLButtonElement;
			N_selectPlayersBtn.addEventListener2('click', async () => {
				(new SelectPlayers(this.game)).open().then(() => {
					this.update();
				});
			});

			this.update();
		});
	}

	private update() {
		this.drawRanks();
		this.drawRounds();
	}

	private drawRanks() {
		try {
			const rounds = this.game.getRounds();
			const players = this.game.getPlayers();
			const totalsMap: Map<string, number> = new Map();
			const N_playerList = this.element.querySelector('#players-list') as HTMLUListElement;

			for (const round of rounds) {
				for (const playerID in round) {
					const s = totalsMap.get(playerID) || 0;
					totalsMap.set(playerID, s + round[playerID]);
				}
			}

			let totals = _.toPairs(totalsMap);
			totals = _.sortBy(totals, 1).reverse();

			const topPlayerCount = getTopPlayerCount(players.size);

			N_playerList.innerHTML = '';
			for (let i = 0; i < totals.length; i++) {
				const [playerID, total] = totals[i];

				const player = players.get(playerID);
				if (player) {
					const N_item = h('li.list-group-item.d-flex.justify-content-between.align-items-center',
						h('span', `${player.lastname[0]}. ${player.firstname}`),
						h('span.label', total)
					) as HTMLElement;
					N_item.style.order = total + '';
					N_playerList.appendChild(N_item);

					if (total >= 0) {
						//Rang de premier
						if (i < topPlayerCount) {
							N_item.classList.add(`rank-winner-${i + 1}`);
						}

						//Rang de dernier
						if (i >= totals.length - topPlayerCount) {
							N_item.classList.add(`rank-looser-${totals.length - i}`);
						}
					}
				}
			}
		} catch (e) {
		}
	}

	private drawRounds() {
		const N_table = this.element.querySelector('#rounds-table') as HTMLTableElement;
		N_table.innerHTML = '';

		const rounds = this.game.getRounds();
		const players = this.game.getPlayers();

		const N_row = h('tr');
		for (const player of players.values()) {
			N_row.appendChild(h('th', player.firstname.slice(0, 2)));
		}
		N_row.appendChild(h('th', { style: 'width:30px' }, '--'));

		N_table.appendChild(h('thead.thead-light', N_row));

		for (let i = 0; i < rounds.length; i++) {
			const round = rounds[i];
			const N_row = N_table.insertRow();

			const scores = _.toPairs(round);
			const ranks = _.map(_.sortBy(scores, 1).reverse(), '0');

			const topPlayerCount = getTopPlayerCount(players.size);
			const topPlayers = ranks.slice(0, topPlayerCount);
			const worstPlayers = ranks.slice(0 - topPlayerCount);

			for (const pID of players.keys()) {
				const N_cell = h('td', round[pID]);
				if (topPlayers.includes(pID)) {
					N_cell.classList.add(`rank-winner-${topPlayers.indexOf(pID) + 1}`);
				}
				if (worstPlayers.includes(pID)) {
					N_cell.classList.add(`rank-looser-${worstPlayers.length - (worstPlayers.indexOf(pID))}`);
				}

				N_row.appendChild(N_cell);
			}

			const N_editBtn = h('button.btn.btn-xs.btn-info', h('i.icon.icon-solid-pen'));
			N_editBtn.addEventListener2('click', () => {
				(new AddEditRound(this.game, i)).open().finally(() => {
					this.update();
				});
			});
			N_row.appendChild(h('td', N_editBtn));
		}
	}

	public async resolve() {
		new ShameLooser(this.game).open().finally(() => {
			super.resolve();
		});
	}
}

export default EditGame;
