import { Modal } from '@autoprog/core-client';
import _ from 'lodash';

import T_ShameLooser from '../../tpl/modals/shameLooser.html';

import Game from '../libs/Game';

class ShameLooser extends Modal {
	private game: Game;

	private gifIDs: string[];

	public constructor(game: Game) {
		super({
			tpl: T_ShameLooser,
			backdrop: 'static'
		});

		this.game = game;
		this.gifIDs = [

			'xsATxBQfeKHCg',
			'S8uzfu5NFflio',
			'3oz8xGvUfhxbjLmZ3O',
			'cr9vIO7NsP5cY',
			'8PUuNcwsmSHle',
			'zOnH0x3nbKS2c',
			'CoDp6NnSmItoY',
			'3i7zenReaUuI0',
			'Rgt0G0tm1Lt8aXgKk9',
			'7rj2ZgttvgomY',
			'l3q2XhfQ8oCkm1Ts4',
			'vcKEsYOdjoCeJRpn95'

		];

		this.on('opened', async () => {
			const rounds = this.game.getRounds();
			const players = this.game.getPlayers();
			const totalsMap: Map<string, number> = new Map();

			for (const round of rounds) {
				for (const playerID in round) {
					const s = totalsMap.get(playerID) || 0;
					totalsMap.set(playerID, s + round[playerID]);
				}
			}

			let totals = _.toPairs(totalsMap);
			totals = _.sortBy(totals, 1);
			const looser = players.get(totals[0][0]);
			if (looser) {
				const N_looserName = this.element.querySelector('#looser-name') as HTMLElement;
				N_looserName.innerHTML = `${looser.firstname}! `;

				const N_looserGif = this.element.querySelector('#looser-gif') as HTMLImageElement;
				const gifID = _.sample(this.gifIDs);
				if (gifID) {
					N_looserGif.src = `https://media.giphy.com/media/${gifID}/giphy.gif`;
				}
			}
		});
	}
}

export default ShameLooser;
