import { Controller, Tabs, global } from '@autoprog/core-client';

import C_Codes from '../tabs/Qrcode.codes';
import C_users from '../tabs/QrCode.users';

import T_blockTabs from '@tpl/blocks/tabs.html';

import History from '@libs/History';

class QrCodeCtrl extends Controller {
	constructor(el: HTMLElement, data: any, params: any) {
		super(el);

		if (global.IS_MOBILE) {
			el.querySelectorAll('[data-type="desktop"]').forEach(N_el => N_el.remove());
		} else {
			el.querySelectorAll('[data-type="mobile"]').forEach(N_el => N_el.remove());
		}

		params = params || {};

		const defaultTab = params.tabs ? params.tabs : 'codes';

		const navElement = el.querySelector('.tabs-container') as HTMLElement;

		const tabs = new Tabs({
			navElement,
			renderElement: el.querySelector('#page-content') as HTMLElement
		});

		tabs.add({
			name: 'Codes',
			id: 'codes',
			default: defaultTab === 'codes',
			controller: C_Codes as any,
			permission: 'QRCODES.SHOW',
			template: T_blockTabs
		});

		tabs.add({
			name: 'Utilisateurs',
			id: 'users',
			default: defaultTab === 'users',
			controller: C_users as any,
			permission: 'QRCODES._USERS.SHOW',
			template: T_blockTabs
		});

		let firstLoad = true;
		tabs.on('change', (data: any) => {
			if (firstLoad) {
				firstLoad = false;
			} else {
				History.getInstance().pushState(`#module/qrcode/${data.id}`);
			}
		});

		if (global.IS_MOBILE) {
			navElement.classList.add('d-none');
		}
	}

	public destructor() {

	}
}

export default QrCodeCtrl;
