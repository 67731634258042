import { Modal } from '@autoprog/core-client';

import _ from 'lodash';
import h from 'hyperscript';

import T_PreviousRoundRecap from '../../tpl/modals/previousRoundRecap.html';

import Game from '../libs/Game';
import getTopPlayerCount from '../libs/getTopPlayerCount';
import getWhatsGiven from '../libs/getWhatsGiven';

class PreviousRoundRecap extends Modal {
	private game: Game;

	public constructor(game: Game) {
		super({
			tpl: T_PreviousRoundRecap,
			backdrop: 'static'
		});

		this.game = game;

		this.on('opened', async () => {
			const lastRound = this.game.getLastRound();
			const players = this.game.getPlayers();
			const topPlayersCount = getTopPlayerCount(players.size);
			const N_container = this.element.querySelector('#players-container') as HTMLElement;

			const scores = _.sortBy(_.toPairs(lastRound), 1).reverse();
			const given = getWhatsGiven(players.size);

			for (let i = 0; i < topPlayersCount; i++) {
				const giver = players.get(scores[scores.length - 1 - i][0]);
				const receiver = players.get(scores[i][0]);

				if (giver && receiver) {
					N_container.appendChild(
						h('div.player',
							h('div.picture', `${giver.firstname[0]}${giver.lastname[0]}`),
							h('span.name', `${giver.lastname[0]}. ${giver.firstname}`)
						));
					N_container.appendChild(
						h('div.d-flex.flex-column.justify-content-center',
							h('div.deck',
								given[i].map((type) => {
									return h(`div.deck-card.${type}`);
								})
							),
							h('i.icon.icon-solid-long-arrow-alt-right.right-arrow')
						));
					N_container.appendChild(
						h('div.player',
							h('div.picture', `${receiver.firstname[0]}${receiver.lastname[0]}`),
							h('span.name', `${receiver.lastname[0]}. ${receiver.firstname}`)
						)
					);
				}
			}
		});
	}
}

export default PreviousRoundRecap;
