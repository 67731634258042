import { Tab } from '@autoprog/core-client';
import hash from 'hash.js';

class CryptoTab extends Tab {
	constructor(el: HTMLElement) {
		super(el);

		const N_value = el.querySelector('#value') as HTMLTextAreaElement;
		const N_result = el.querySelector('#result') as HTMLTextAreaElement;

		const keys = [
			'ripemd160',
			'sha1',
			'sha224',
			'sha384',
			'sha256',
			'sha512'
		];

		for (const func of keys) {
			const N_el = el.querySelector(`#${func}`) as HTMLButtonElement;

			N_el.addEventListener2('click', () => {
				N_result.value = (hash as any)[func]().update(N_value.value).digest('hex').toString();
			});
		}
	}

	public destructor() {
	}
}

export default CryptoTab;
