import { Module, Modules, Router } from '@autoprog/core-client';

import C_Interface from './js/controllers/Interface';

import T_tabs from '@tpl/tabs.html';

import Navigation from '@libs/Navigation';

class InterfacesModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);
	}

	public async preInit() {
		const navigation = Navigation.getInstance();
		navigation.addCategory({
			name: 'Outils',
			order: 200
		});
		navigation.addLink({
			order: 4,
			category: 'Outils',
			name: 'Interface',
			type: 'module',
			module: 'interface',
			permissions: ['INTERFACE'],
			icon: 'list-unordered'
		});
	}

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: '/module/interface',
			controller: C_Interface as any,
			tpl: T_tabs,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/interface/:tabs',
			controller: C_Interface as any,
			tpl: T_tabs,
			requireValidUser: true
		});
	}

	public async postInit() {
	}
}

Modules.registerModule('Interface', InterfacesModule);
